import React from 'react';
import './About.css';
import website from '../assets/images/return-polic.jpg';

const AboutUs = () => {
  return (
    <div>
      <div className="hero-banner bg1">
    <div className="container">
      <div className="row justify-content-between align-items-center g-5 ">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">legal</h6>
            <h1 className="mb-3">
              Refund  <span className="highlight">Policy</span> 
            </h1>
            <p>
            

Hosting Aviator offers a 7-day money-back guarantee for web hosting plans. Eligible refunds exclude a 10% transaction fee. Domain registration and VPS server purchases are non-refundable. Refunds are processed promptly upon request via support channels. Terms may change; please review regularly for updates.            </p>
            
            {" "}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          {" "}
          <img src={website} alt="website" />{" "}
        </div>
      </div>
    </div>
  </div>
      <section className="theme-section-gap">
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="theme-section-heading text-center">
            <h2>
              <span className="highlight">Refund</span> Policy
            </h2>
          </div>
        </div>
        <div className="row justify-content-stretch align-items-stretch g-100">
          <div className="col-lg- col-md-100 col-100">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-hdd-network" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-hdd-network"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a.5.5 0 0 1 0 1H10A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5H.5a.5.5 0 0 1 0-1H6A1.5 1.5 0 0 1 7.5 10V7H2a2 2 0 0 1-2-2V4zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1zm6 7.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5z" />
                </svg>
              </div>
              <div className="card-content">
                 
                <p>
                <h3> 
                <p > HOSTING AVIATOR, we are committed to providing you with top-notch hosting services and ensuring your
                        satisfaction. Our refund policy outlines the terms and conditions for requesting a refund for our
                        hosting services. We proudly offer a 7-day money-back guarantee for our web hosting plans. If, for
                        any reason, you are not satisfied with our hosting services within the first 7 days of your initial
                        purchase, you may request a refund. For eligible refund requests within the 7-day period, a refund
                        equivalent to 90% of the total hosting plan cost will be processed. The remaining 10% will be
                        retained to cover gateway and transaction fees incurred during the refund process.We want to inform
                        you that domain registration fees are non-refundable. Once a domain is registered or renewed, we are
                        unable to process refunds for domain-related charges. To initiate a refund request for hosting
                        services, please reach out to our customer support team through our designated support channels. We
                        will assist you in processing your hosting service refund efficiently. Hosting service refunds will
                        be processed promptly. However, please be aware that the time it takes for the refunded amount to
                        reflect in your account may vary depending on your payment method and financial institution.</p> 
                        <p style={{ color: 'black' }}><strong>*Once A Domain Is Purchased It Cannot Be Cancelled Or Refund.</strong></p>
<p style={{ color: 'black' }}><strong>*7 Days Money Back Guarantee Only On Web Hosting Plans.</strong></p>
<p style={{ color: 'black' }}><strong>*Once A Vps Server Is Purchased It Cannot Be Cancelled Or Refund.</strong></p>
</h3>
             </p>
                {" "}
              </div>
            </div>
          </div>
          
          

          


          

        
        </div>
      </div>
    </section>
    </div>
  );
};

export default AboutUs;
