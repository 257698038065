// src/pages/BusinessEmail.jsx
import React from 'react';
import email from '../assets/images/email.svg'
const BusinessEmail = () => {
  return (
    <div>
      <>
  <div className="hero-banner bg5">
    <div className="container">
      <div className="row justify-content-between align-items-center g-5">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">Bussiness Email</h6>
            <h1>
              Looking for professional{" "}
              <span className="highlight">business email</span>{" "}
            </h1>
            <p className="big mb-3">
              Give your business a professional makeover with
              email@yourbrand.com{" "}
            </p>
            <a className="theme-btn three width-increase" href="#plans">
              <i className="bi bi-hdd-stack start" /> View Plan
            </a>{" "}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          {" "}
          <img
            src={email}
            alt="images8"
            className="mix-dark d-block m-auto"
          />{" "}
        </div>
      </div>
    </div>
  </div>
  <main>
    <section className="theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading center">
            <h2>
              why <span className="highlight">Business Email</span> useful for
              you ?
            </h2>
          </div>
        </div>
        <div className="row justify-content-between g-4 align-item-stretch mt-4">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-envelope-open"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882l-6-3.2ZM15 7.383l-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2Z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  {" "}
                  <span className="highlight">Intuitive </span> and Responsive
                  Design
                </h3>
                <p>
                  Beautifully designed state of the art webmail platform. You
                  can also access your email on your smartphone or tablet.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-globe2"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Inbuilt Virus <span className="highlight">Protection</span>
                </h3>
                <p>
                  Our advanced anti-virus technology secured your inbox and
                  ensures that you are protected from downloading malware and
                  viruses.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-tv"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  5GB Storage Plus <span className="highlight"> Backup</span>{" "}
                </h3>
                <p>
                  In addition to 5GB mail storage, your emails are backed up in
                  our state-of-the-art infrastructure so that you never lose
                  important mails.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-globe-americas"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Get Additional <span className="highlight">Storage</span> @ $
                  0.53/5GB{" "}
                </h3>
                <p>
                  No more worrying about running out of storage space. You can
                  now increase storage for individual accounts by buying
                  additional storage blocks of 5 GB.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-inboxes"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562A.5.5 0 0 0 1.884 9h12.234a.5.5 0 0 0 .496-.438L14.933 6zM3.809.563A1.5 1.5 0 0 1 4.981 0h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393zm.941.83.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438l.32-2.562H10.45a2.5 2.5 0 0 1-4.9 0H1.066z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  100% Uptime and <span className="highlight">Security</span>{" "}
                </h3>
                <p>
                  Our high-end mail storage infrastructure guarantees zero data
                  loss and redundancy, along with 100% network uptime{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-tools"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Calendars and <span className="highlight">Contacts</span>
                </h3>
                <p>
                  Manage contacts and keep track of all your meetings in one
                  place with advanced productivity tools.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap plan-two bgone" id="plans">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="theme-section-heading text-center">
            <h2>
              Select Your <span className="highlight">Plan</span>
            </h2>
          </div>
        </div>
        <div className="row justify-content-center g-4 mt-4">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="plan-two-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Business </h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">9.59</span>
                <sub>&nbsp; /mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    single <span>&nbsp; Domain</span>
                  </li>
                  <li>
                    Unmetered <span>&nbsp; Disk Space</span>
                  </li>
                  <li>
                    Unmetered <span>&nbsp; Data Transfer</span>
                  </li>
                  <li>
                    Unlimited <span>&nbsp; Email Accounts </span>
                  </li>
                  <li>
                    {" "}
                    <span>
                      <a href="#">&nbsp;Free SSL Certificate</a>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="border mb-2 mt-2" />
              <div className="theme-btn two mt-3">
                {" "}
                <a href="#">Buy Plan</a>{" "}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="plan-two-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Pro </h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">19.65</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    2 <span>&nbsp; Domain</span>
                  </li>
                  <li>
                    30 <span>&nbsp; Disk Space</span>
                  </li>
                  <li>
                    120 <span>&nbsp; Data Transfer</span>
                  </li>
                  <li>
                    Unlimited <span>&nbsp; Email Accounts </span>
                  </li>
                  <li>
                    {" "}
                    <span>
                      <a href="#">&nbsp; Free SSL Certificate</a>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="border mb-2 mt-2" />
              <div className="theme-btn two mt-3">
                {" "}
                <a href="#">Buy Plan</a>{" "}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="plan-two-card active text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Unlimited Hosting Plan</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">29.53</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    5 <span>&nbsp; Domain</span>
                  </li>
                  <li>
                    40 <span>&nbsp; Disk Space</span>
                  </li>
                  <li>
                    300 <span>&nbsp; Data Transfer</span>
                  </li>
                  <li>
                    Unlimited <span>&nbsp; Email Accounts </span>
                  </li>
                  <li>
                    {" "}
                    <span>
                      <a href="#">&nbsp;Free SSL Certificate</a>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="border mb-2 mt-2" />
              <div className="theme-btn two mt-3">
                {" "}
                <a href="#">Buy Plan</a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="testimonial theme-section-gap bg-secondary-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="theme-section-heading center">
              <h2>
                Hear from happy <span className="highlight">customers</span>
              </h2>
              <p>
                Our satisfied customers love our products/services. Read their
                glowing testimonials to discover why they choose us for quality
                and excellence.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="swiper testimonials">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Aaron Sweeney</h3>
                    <h4>Co-Founder</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Great
                      support, like i have never seen before. Thanks to the
                      support team, they are very helpfull. This company provide
                      customers great solution, that makes them best.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-2.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Animi
                      perferendis, commodi veritatis unde magnam magni? Eaque,
                      reprehenderit dignissimos?{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-3.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Simon Deubele</h3>
                    <h4>Team Member</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> I
                      chose you because you create added value over the price
                      offered by other hosting companies. it's been a great
                      experience for me. I'm leaving happy. Thank you.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap customer-support">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-12">
            <div className="bg2 customer-support-box">
              <div className="row justify-content-between g-5">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="theme-section-heading text-start">
                    <h6 className="style">Contact Us</h6>
                    <h2 className="mt-3">
                      Any query ? <span className="highlight">let's talk</span>
                    </h2>
                    <p>
                      Any questions or topics you'd like to discuss? Feel free
                      to share your thoughts, and let's engage in a
                      conversation. I'm here to help and chat with you.
                    </p>
                    <div className="theme-btn two mt-3">
                      {" "}
                      <a href="https://manage.hostingaviator.cloud/contact.php">Support Center</a>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-envelope-at-fill" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">Email us</span>{" "}
                      <a href="mailto:example@gmail.com">example@gmail.com</a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-phone-flip" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">call</span>{" "}
                      <a href="tel:1234567899">+91 123 456 7890</a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-geo-alt" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">Our location</span>{" "}
                      <a target="_blank" href="https://www.google.com/maps">
                        Open Google Maps
                      </a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="faq theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center mb-4">
            <h2>
              Frequntly <b className="highlight heading">Asked </b> Question
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <div className="faq-qa">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {" "}
                      How will purchasing Business Email benefit me ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      As opposed to free email solutions, you can give your
                      business a more professional image with Business Email by
                      getting email that is branded with your company's domain
                      name (ex. sales@mybrand.com). In addition, you also
                      benefit from our advanced email technology that gives you
                      the least latency and industry-best uptime, scalability
                      and reliability. An email service being served out of the
                      cloud also means no IT, hardware, software, bandwidth or
                      people costs. And the best part is that you can add email
                      accounts as and when your team grows.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {" "}
                      Which Email Clients and protocols are supported ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      You can send and receive emails using any desktop-based
                      email client such as Microsoft Outlook, Outlook Express,
                      Mozilla Thunderbird, Eudora, Entourage 2004, Windows Mail,
                      etc. We also have a guide on how you can configure
                      different email clients to send/receive emails. The
                      enterprise email product supports the POP, IMAP and MAPI
                      protocols.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      How do I use my Webmail Interface ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      To access your Webmail Interface, you can use the
                      white-labelled URL: http://webmail.yourdomainname.com .
                      Once on the log in page, you would need to login with your
                      email address and the corresponding password.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {" "}
                      Which mobile phones can I access my mail from ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Your email can be accessed using any Smartphone or Tablet.
                      Our fluidic webmail, built on HTML 5 &amp; Javascript, is
                      compatible on all major Operating systems such as iOS,
                      Android, Windows Mobile, Symbian and Blackberry.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {" "}
                      What is the space provided per Email Account ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Each email account comes with 5 GB space dedicated to
                      emails.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      {" "}
                      What ports do I need to use for Email Hosting ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Usually, the port used for the Outgoing Mail Server/SMTP
                      Service is 25. However, there might be a situation where
                      your ISP might be blocking the use of port 25 for SMTP
                      service. To circumvent this you can use an alternate port
                      587 for sending mails.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      {" "}
                      Can I create mailing lists ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, you can create mailing lists and add/delete users,
                      select a moderator, restrict people from joining a list or
                      even ban users from a list. More information on this can
                      be found in our knowledgebase.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      {" "}
                      What is your SPAM policy ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      We take a zero tolerance stance against sending of
                      unsolicited email, bulk emailing, and spam. "Safe lists",
                      purchased lists, and selling of lists will be treated as
                      spam. Any user who sends out spam will have their account
                      terminated with or without notice.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      {" "}
                      Can I use Auto Responders ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, you can. An auto-responder is a program that, when
                      setup for your email address, sends out an automatic,
                      pre-set reply to an email, as soon as it is received at
                      this email address. You can find out more about setting up
                      an auto-responder from our knowledge base.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</>

    </div>
  );
};

export default BusinessEmail;
