// src/pages/DedicatedServer.jsx
import React from 'react';

const DedicatedServer = () => {
  return (
    <div>
      <>
  <div className="hero-banner bg5">
    <div className="container">
      <div className="row justify-content-between align-items-center gy-lg-4">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">Dedicated</h6>
            <h1 className="mb-3">
              {" "}
              <span className="highlight">Dedicated Servers</span>
            </h1>
            <p className="">
              Get all the raw performance of the server for your
              resource-intensive websites &amp; applications
            </p>
            <ul className="banner-list mt-3 mb-3">
              <li>Choose from SSD or HDD plans</li>
              <li>High-memory servers</li>
              <li>New Generation Processors</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="plan-banner text-center">
            {" "}
            <span className="highlighteed">Basic Dedicated server</span>
            <ul>
              <li>
                <i className="bi bi-hand-thumbs-up-fill" />
                30 Days Money Return Available
              </li>
              <li>
                <i className="bi bi-hand-thumbs-up-fill" />
                99.999% uptime
              </li>
              <li>
                <i className="bi bi-hand-thumbs-up-fill" />
                Get started within minutes
              </li>
            </ul>
            <h2 className="offer-price color-primary mt-2">
              {" "}
              <sup>$</sup> 24.31 <sub className="fs-15">/month</sub>{" "}
            </h2>
            <div className="text-center mt-3">
              {" "}
              <a
                className="theme-btn three width-increase m-auto mb-3"
                href="#plans"
              >
                Get Started Now
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*
  <main>
    <section className="theme-section-gap dedicated-plan">
      <div className="container">
        <div className="row justify-content-center g-5">
          <div className="theme-section-heading text-center">
            <h2>
              Choose The Best Dedicated{" "}
              <span className="highlight">Server Plan</span>
            </h2>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-8 d-lg-block d-none dedicated-plan-head m-auto">
            <div className="row justify-content-center">
              <div className="col-lg-3">
                <div className="dedicated-plan-card text-center">
                  {" "}
                  <span>CPU</span>{" "}
                </div>
              </div>
              <div className="col-lg-3">
                <div className="dedicated-plan-card text-center">
                  {" "}
                  <span>RAM</span>{" "}
                </div>
              </div>
              <div className="col-lg-3">
                <div className=" dedicated-plan-card text-center">
                  {" "}
                  <span>Storage</span>{" "}
                </div>
              </div>
              <div className="col-lg-3">
                <div className="dedicated-plan-card text-center">
                  {" "}
                  <span>Price</span>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 g-3">
          <div className="col-12 dedicated-plan-block col-md-6 col-lg-12">
            <div className="row justify-content-center">
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title">
                    {" "}
                    <b>AMD Ryzen 1 PRO 1700X</b>{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="CPU">
                    {" "}
                    8C/16T, 3.8 Ghz, 16MB Cache, 95W{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="RAM">
                    {" "}
                    4x RAM 16384 MB DDR4 GB{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="Storage">
                    {" "}
                    2x SSD SATA 512 GB{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title price-title" data-list="Price">
                    {" "}
                    <span className="highlight"> $13.15/mo</span>{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <a className="theme-btn three width-increase" href="#">
                    {" "}
                    Order <i className="bi bi-arrow-right m-0" />
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 dedicated-plan-block col-md-6 col-lg-12">
            <div className="row justify-content-center">
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title">
                    {" "}
                    <b>AMD Ryzen 2 PRO 1700X</b>{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="CPU">
                    {" "}
                    8C/16T, 3.8 Ghz, 16MB Cache, 95W{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="RAM">
                    {" "}
                    4x RAM 16384 MB DDR4 GB{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="Storage">
                    {" "}
                    2x SSD SATA 512 GB{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title price-title" data-list="Price">
                    {" "}
                    <span className="highlight"> $22.45/mo</span>{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <a className="theme-btn three width-increase" href="#">
                    {" "}
                    Order <i className="bi bi-arrow-right m-0" />
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 dedicated-plan-block col-md-6 col-lg-12">
            <div className="row justify-content-center">
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title">
                    {" "}
                    <b>AMD Ryzen 3 PRO 1700X</b>{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="CPU">
                    {" "}
                    8C/16T, 3.8 Ghz, 16MB Cache, 95W{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="RAM">
                    {" "}
                    4x RAM 16384 MB DDR4 GB{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="Storage">
                    {" "}
                    2x SSD SATA 512 GB{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title price-title" data-list="Price">
                    {" "}
                    <span className="highlight"> $32.67/mo</span>{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <a className="theme-btn three width-increase" href="#">
                    {" "}
                    Order <i className="bi bi-arrow-right m-0" />
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 dedicated-plan-block col-md-6 col-lg-12">
            <div className="row justify-content-center">
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title">
                    {" "}
                    <b>AMD Ryzen 4 PRO 1700X </b>{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="CPU">
                    {" "}
                    8C/16T, 3.8 Ghz, 16MB Cache, 95W{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="RAM">
                    {" "}
                    4x RAM 16384 MB DDR4 GB{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title" data-list="Storage">
                    {" "}
                    2x SSD SATA 512 GB{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <span className="heading-title price-title" data-list="Price">
                    {" "}
                    <span className="highlight"> $43.143/mo</span>{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dedicated-plan-text">
                  {" "}
                  <a className="theme-btn three width-increase" href="#">
                    {" "}
                    Order <i className="bi bi-arrow-right m-0" />
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading center">
            <h2>
              Maximum Performance,{" "}
              <span className="highlight">Total Control</span>
            </h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
          </div>
        </div>
        <div className="row justify-content-between g-4 align-item-stretch mt-4">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-laptop"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Powerful <span className="highlight">Configurations</span>
                </h3>
                <p>
                  Our servers are powered by SSD storage, DDR4 Memory and Xenon
                  D processors, and can smoothly handle any type of workload —
                  from a critical business application to a high-traffic
                  website.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-layout-text-sidebar"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM3 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm12-1v14h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zm-1 0H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h9V1z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Quick <span className="highlight">Provisioning</span>
                </h3>
                <p>
                  We get you online quickly. Your server is provisioned within
                  minutes.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-magnet-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M15 12h-4v3h4v-3ZM5 12H1v3h4v-3ZM0 8a8 8 0 1 1 16 0v8h-6V8a2 2 0 1 0-4 0v8H0V8Z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  <span className="highlight">Server </span> Administration
                  Panel
                </h3>
                <p>
                  Never lose access to your server. Our Server Administration
                  Panel gives you total control at all times. With features like
                  Rebuild, Web-based VNC, Restart, Shutdown and Resource
                  Monitoring you can manage your server easily.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-messenger"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Additional <span className="highlight">Storage*</span>{" "}
                </h3>
                <p>
                  Our HDD servers come with the flexibility of additional
                  storage. You can easily scale the storage as your business
                  grows.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-nintendo-switch"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.34 8.005c0-4.38.01-7.972.023-7.982C9.373.01 10.036 0 10.831 0c1.153 0 1.51.01 1.743.05 1.73.298 3.045 1.6 3.373 3.326.046.242.053.809.053 4.61 0 4.06.005 4.537-.123 4.976-.022.076-.048.15-.08.242a4.136 4.136 0 0 1-3.426 2.767c-.317.033-2.889.046-2.978.013-.05-.02-.053-.752-.053-7.979Zm4.675.269a1.621 1.621 0 0 0-1.113-1.034 1.609 1.609 0 0 0-1.938 1.073 1.9 1.9 0 0 0-.014.935 1.632 1.632 0 0 0 1.952 1.107c.51-.136.908-.504 1.11-1.028.11-.285.113-.742.003-1.053ZM3.71 3.317c-.208.04-.526.199-.695.348-.348.301-.52.729-.494 1.232.013.262.03.332.136.544.155.321.39.556.712.715.222.11.278.123.567.133.261.01.354 0 .53-.06.719-.242 1.153-.94 1.03-1.656-.142-.852-.95-1.422-1.786-1.256Z" />
                  <path d="M3.425.053a4.136 4.136 0 0 0-3.28 3.015C0 3.628-.01 3.956.005 8.3c.01 3.99.014 4.082.08 4.39.368 1.66 1.548 2.844 3.224 3.235.22.05.497.06 2.29.07 1.856.012 2.048.009 2.097-.04.05-.05.053-.69.053-7.94 0-5.374-.01-7.906-.033-7.952-.033-.06-.09-.063-2.03-.06-1.578.004-2.052.014-2.26.05Zm3 14.665-1.35-.016c-1.242-.013-1.375-.02-1.623-.083a2.81 2.81 0 0 1-2.08-2.167c-.074-.335-.074-8.579-.004-8.907a2.845 2.845 0 0 1 1.716-2.05c.438-.176.64-.196 2.058-.2l1.282-.003v13.426Z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Easily <span className="highlight">Configurable</span>{" "}
                </h3>
                <p>
                  Choose from multiple Linux flavours and hosting panels. You
                  can even add WHMCS add-on to have a complete billing solution.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-pc-display"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V1Zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0ZM9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5ZM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5ZM1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2H1.5Z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  99.99% <span className="highlight">uptime</span>{" "}
                </h3>
                <p>
                  Our servers are located a top-tier data centres which are
                  backed by redundant ISP links and Neustar DDoS protection to
                  ensure your site is up and available all the time.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap customer-support">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-12">
            <div className="bg2 customer-support-box">
              <div className="row justify-content-between g-5">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="theme-section-heading text-start">
                    <h6 className="style">Contact Us</h6>
                    <h2 className="mt-3">
                      Any query ? <span className="highlight">let's talk</span>
                    </h2>
                    <p>
                      Any questions or topics you'd like to discuss? Feel free
                      to share your thoughts, and let's engage in a
                      conversation. I'm here to help and chat with you.
                    </p>
                    <div className="theme-btn two mt-3">
                      {" "}
                      <a href="contacts.html">Support Center</a>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-envelope-at-fill" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">Email us</span>{" "}
                      <a href="mailto:example@gmail.com">example@gmail.com</a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-phone-flip" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">call</span>{" "}
                      <a href="tel:1234567899">+91 123 456 7890</a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-geo-alt" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">Our location</span>{" "}
                      <a target="_blank" href="https://www.google.com/maps">
                        Open Google Maps
                      </a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="testimonial theme-section-gap bg-secondary-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="theme-section-heading center">
              <h2>
                Hear from happy <span className="highlight">customers</span>
              </h2>
              <p>
                Our satisfied customers love our products/services. Read their
                glowing testimonials to discover why they choose us for quality
                and excellence.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="swiper testimonials">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Aaron Sweeney</h3>
                    <h4>Co-Founder</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Great
                      support, like i have never seen before. Thanks to the
                      support team, they are very helpfull. This company provide
                      customers great solution, that makes them best.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-2.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Animi
                      perferendis, commodi veritatis unde magnam magni? Eaque,
                      reprehenderit dignissimos?{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-3.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Simon Deubele</h3>
                    <h4>Team Member</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> I
                      chose you because you create added value over the price
                      offered by other hosting companies. it's been a great
                      experience for me. I'm leaving happy. Thank you.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="faq theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center mb-4">
            <h2>
              Frequntly <b className="highlight heading">Asked </b> Question
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <div className="faq-qa">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {" "}
                      Which hosting control panels do you offer ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      You can buy Plesk or cPanel license during the server
                      purchase, or at a later time when the need arises.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {" "}
                      Which billing panels do you offer ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      We offer WHMCS as a billing panel for your dedicated
                      server. You can purchase the license from us at a minimal
                      cost and install the application on your dedicated server.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      What are the functions of the Server Administration Panel
                      ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Every server comes with its Server Administration Panel by
                      default. This panel allows you to carry out crucial server
                      administration tasks and monitor resource consumption like
                      GUI-based CPU, Memory, Storage &amp; Inodes utilisation,
                      IPs and Storage (for HDD servers only) details and
                      functions to Rebuild, Restart, Web-based VNC and reset
                      access credentials.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {" "}
                      Can I configure my own RAID for drives ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      The Dedicated Servers come pre-configured with RAID 1. At
                      present, we don’t allow customisation of RAID level. Would
                      love to hear about such new features requests to help us
                      serve you better.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {" "}
                      What is the port speed of the server ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      The server uplink port speed is up to 1 Gbps.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      {" "}
                      How long does it take for the server to be ready ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      After the payment, your server will be provisioned in
                      about 30 minutes and you can access it via SSH. The disk
                      resize operation, however, may still run in the background
                      for a while after provisioning.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      {" "}
                      How long does it take for the server to be ready ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      To ensure high uptime and accessibility at all times, we
                      are available 24x7 for any hardware, network, booting,
                      O.S. or login issues. Our System Administration Support
                      will also assist you with basic cPanel &amp; Firewall
                      setup and their issues.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      {" "}
                      Is Additional Storage available for all server
                      configurations ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      At present, the additional storage feature is only
                      available with our HDD servers in the US data centre.Would
                      love to hear about such new features requests to help us
                      serve you better.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      {" "}
                      Can I use a Dedicated Server for email marketing ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Dedicated Servers can be used for Websites, Databases,
                      Custom Applications, Ecommerce, DNS, File Storage and
                      Emails. Emails, however, must be restricted to personal,
                      organisational or professional purposes. The use of
                      Dedicated Servers to send out mass emails/marketing is NOT
                      recommended and can attract penalties.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      {" "}
                      Can I use virtualisation software on the server ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTen"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Our Dedicated Servers are virtualised (1:1). Thus, nested
                      virtualisation will not work due to network restrictions
                      on the host system.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEleven"
                      aria-expanded="false"
                      aria-controls="collapseEleven"
                    >
                      {" "}
                      Do you provide any backup service ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseEleven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      We don’t have any backup solution at the moment. Yet, we
                      strongly recommend that you maintain a remote backup to
                      avoid any hassles during any ill-fated incident.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  */}

<div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#f4f4f4",
        color: "#333",
        fontFamily: "Arial, sans-serif",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          fontSize: "3rem",
          color: "#0066cc",
          marginBottom: "10px",
        }}
      >
        Coming Soon
      </h1>
      <p
        style={{
          fontSize: "1.5rem",
          marginBottom: "30px",
        }}
      >
        Our dedicated-server is launching soon. Stay tuned for updates!
      </p>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            fontSize: "2rem",
            padding: "10px 20px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
        00
        </div>
        <div
          style={{
            fontSize: "2rem",
            padding: "10px 20px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          00
        </div>
        <div
          style={{
            fontSize: "2rem",
            padding: "10px 20px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          00
        </div>
        <div
          style={{
            fontSize: "2rem",
            padding: "10px 20px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          00
        </div>
      </div>
      <button
        style={{
          padding: "10px 20px",
          fontSize: "1rem",
          color: "#fff",
          backgroundColor: "#0066cc",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={() => alert("You will be notified!")}
      >
        Notify Me
      </button>
    </div>

</>

    </div>
  );
};

export default DedicatedServer;
