// src/pages/CodeguardBackup.jsx
import React from 'react';

const CodeguardBackup = () => {
  return (
    <div>
      <>
  <div className="hero-banner bg4">
    <div className="container">
      <div className="row justify-content-between align-items-center gy-lg-4">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">Codeguard</h6>
            <h1 className="mb-3">
              Cloud backup for your <span className="highlight">website</span>
            </h1>
            <p>
              All websites—large &amp; small—run the risk of crashes and data
              loss. CodeGuard's cloud backup lets you recover your website data
              instantly, with just a few clicks.{" "}
            </p>
            <a className="theme-btn three width-increase mt-3" href="#plans">
              <i className="bi bi-hdd-stack start" /> Watch To Learn More
            </a>{" "}
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="plan-banner text-center">
            {" "}
            <span className="highlighteed">Cloud backup</span>
            <ul>
              <li>
                <i className="bi bi-hand-thumbs-up-fill" />
                30 Days Money Return Available
              </li>
              <li>
                <i className="bi bi-hand-thumbs-up-fill" />
                99.999% uptime
              </li>
              <li>
                <i className="bi bi-hand-thumbs-up-fill" />
                Get started within minutes
              </li>
            </ul>
            <h2 className="offer-price color-primary mt-2">
              {" "}
              <sup>$</sup> 24.31 <sub className="fs-15">/month</sub>{" "}
            </h2>
            <div className="text-center mt-3">
              {" "}
              <a
                className="theme-btn three width-increase m-auto mb-3"
                href="#plans"
              >
                Get Started Now
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <main>
    <section className="theme-section-gap">
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="theme-section-heading text-center">
            <h2>
              Website backup that <span className="highlight">just works </span>
            </h2>
          </div>
        </div>
        <div className="row justify-content-center align-items-stretch g-5">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-telegram" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-telegram"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>
                  {" "}
                  Automatic <span className="highlight">backups </span>
                </h3>
                <p>
                  CodeGuard works behind the scenes. Which means you can turn it
                  on and sit back while CodeGuard takes regular backups of your
                  data.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-tools" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-tools"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>
                  {" "}
                  <span className="highlight">Hassle-free </span> setup
                </h3>
                <p>
                  Since CodeGuard is cloud-based, setting it up is a snap –
                  simply add your website connection details to start the backup
                  process.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-coin" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-coin"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>
                  {" "}
                  30-day <b className="highlight heading">Money-back </b>{" "}
                  guarantee{" "}
                </h3>
                <p>
                  Try out CodeGuard and see how easy it is to secure your
                  website. With our 30-day Money-back period, you've got nothing
                  to lose.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap plan-one">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="theme-section-heading text-center">
            <h2>
              Get your business{" "}
              <span className="highlight"> online today </span>
            </h2>
          </div>
        </div>
        <div className="row justify-content-between align-item-stretch g-4 mt-4">
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="text-start">Positive SSL</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">64.20</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <a href="#" className="theme-btn two">
                Purchase Plan
              </a>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>2</span>&nbsp; Cores
                  </li>
                  <li>
                    <span>2</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>20</span>&nbsp; GB SSD
                  </li>
                  <li>
                    <span>1</span> TB Bandwidth
                  </li>
                  <li>
                    <span>0</span> Additional IP
                  </li>
                </ul>
              </div>
              <div className="select">
                <select>
                  <option>1 Month at $ 64.20/mo</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="text-start">Comodo SSL</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">83.41</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <a href="#" className="theme-btn two">
                Purchase Plan
              </a>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>3</span>&nbsp; Cores
                  </li>
                  <li>
                    <span>4</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>40</span>&nbsp; GB SSD
                  </li>
                  <li>
                    <span>1</span> TB Bandwidth
                  </li>
                  <li>
                    <span>0</span> Additional IP
                  </li>
                </ul>
              </div>
              <div className="select">
                <select>
                  <option>1 Month at $ 83.41/mo</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="text-start">EV SSL </h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">88.22</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <a href="#" className="theme-btn two">
                Purchase Plan
              </a>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>4</span>&nbsp; Cores
                  </li>
                  <li>
                    <span>4</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>50</span>&nbsp; GB SSD
                  </li>
                  <li>
                    <span>1</span> TB Bandwidth
                  </li>
                  <li>
                    <span>0</span> Additional IP
                  </li>
                </ul>
              </div>
              <div className="select">
                <select>
                  <option>1 Month at $ 88.22/mo</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="text-start">Standard</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">26.27</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <a href="#" className="theme-btn two">
                Purchase Plan
              </a>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>5</span>&nbsp; Cores
                  </li>
                  <li>
                    <span>4</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>80</span>&nbsp; GB SSD
                  </li>
                  <li>
                    <span>2</span> TB Bandwidth
                  </li>
                  <li>
                    <span>0</span> Additional IP
                  </li>
                </ul>
              </div>
              <div className="select">
                <select>
                  <option>1 Month at $ 26.27/mo</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/*
    <section className="testimonial theme-section-gap bg-secondary-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="theme-section-heading center">
              <h2>
                Hear from happy <span className="highlight">customers</span>
              </h2>
              <p>
                Our satisfied customers love our products/services. Read their
                glowing testimonials to discover why they choose us for quality
                and excellence.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="swiper testimonials">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Aaron Sweeney</h3>
                    <h4>Co-Founder</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Great
                      support, like i have never seen before. Thanks to the
                      support team, they are very helpfull. This company provide
                      customers great solution, that makes them best.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-2.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Animi
                      perferendis, commodi veritatis unde magnam magni? Eaque,
                      reprehenderit dignissimos?{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-3.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Simon Deubele</h3>
                    <h4>Team Member</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> I
                      chose you because you create added value over the price
                      offered by other hosting companies. it's been a great
                      experience for me. I'm leaving happy. Thank you.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>
    </section>
    */}
    <section className="theme-section-gap bgtwo">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center mb-4">
            <h2>
              How it <span className="highlight">Works</span>{" "}
            </h2>
          </div>
        </div>
        <div className="row justify-content-between g-5 align-item-stretch">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="theme-section-heading sitelock-card-style">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                  <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" />
                </svg>
              </div>
              <h3 className="mb-3">Connect your website</h3>
              <p>
                Connect your website to CodeGuard using your SFTP/MySQL
                information. Once connected, an initial backup will start
                automatically.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="theme-section-heading sitelock-card-style">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 640 512"
                >
                  {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                  <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z" />
                </svg>
              </div>
              <h3 className="mb-3">Get regular backup updates</h3>
              <p>
                CodeGuard automatially monitors your website's files &amp;
                databases for changes, performs backups and notifies you by
                email.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="theme-section-heading sitelock-card-style">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                  <path d="M160 64c0-8.8 7.2-16 16-16s16 7.2 16 16V200c0 10.3 6.6 19.5 16.4 22.8s20.6-.1 26.8-8.3c3-3.9 7.6-6.4 12.8-6.4c8.8 0 16 7.2 16 16c0 10.3 6.6 19.5 16.4 22.8s20.6-.1 26.8-8.3c3-3.9 7.6-6.4 12.8-6.4c7.8 0 14.3 5.6 15.7 13c1.6 8.2 7.3 15.1 15.1 18s16.7 1.6 23.3-3.6c2.7-2.1 6.1-3.4 9.9-3.4c8.8 0 16 7.2 16 16l0 16V392c0 39.8-32.2 72-72 72H272 212.3h-.9c-37.4 0-72.4-18.7-93.2-49.9L50.7 312.9c-4.9-7.4-2.9-17.3 4.4-22.2s17.3-2.9 22.2 4.4L116 353.2c5.9 8.8 16.8 12.7 26.9 9.7s17-12.4 17-23V320 64zM176 0c-35.3 0-64 28.7-64 64V261.7C91.2 238 55.5 232.8 28.5 250.7C-.9 270.4-8.9 310.1 10.8 339.5L78.3 440.8c29.7 44.5 79.6 71.2 133.1 71.2h.9H272h56c66.3 0 120-53.7 120-120V288l0-16c0-35.3-28.7-64-64-64c-4.5 0-8.8 .5-13 1.3c-11.7-15.4-30.2-25.3-51-25.3c-6.9 0-13.5 1.1-19.7 3.1C288.7 170.7 269.6 160 248 160c-2.7 0-5.4 .2-8 .5V64c0-35.3-28.7-64-64-64zm48 304c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304zm48-16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304c0-8.8-7.2-16-16-16zm80 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304z" />
                </svg>
              </div>
              <h3 className="mb-3">Restore from any point</h3>
              <p>
                Restore your website's files &amp; database to any previous
                backup version by downloading a zip file, performing an
                automatic restore or restoring individual files.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="faq theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center mb-4">
            <h2>
              Frequntly <b className="highlight heading">Asked </b> Question
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <div className="faq-qa">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {" "}
                      What is CodeGuard ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      CodeGuard is a fully automated website backup service that
                      gives you complete protection against data loss and
                      malware.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {" "}
                      Why do I need CodeGuard ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      CodeGuard provides an independent offsite backup solution
                      for your website along with daily monitoring to ensure
                      your website is online and malware free.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      How does it work ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      CodeGuard takes daily automated snapshots of your website.
                      Using these snapshots, you can restore your entire site or
                      a specific file to an earlier version at any time.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {" "}
                      What if I run out of storage ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Switching plans is easy! You can upgrade and increase your
                      disk storage allowance in just a few simple clicks via our
                      client area.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {" "}
                      Where are backups stored ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Backups are stored on Amazon Web Services Simple Storage
                      System which provides market leading resiliance and
                      redundancy for your backups.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      {" "}
                      Are the backups encrypted ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, backups are stored encrypted using the AES-256
                      Encryption Standard.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      {" "}
                      Do you backup databases ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, databases can be backed up also. Database backups are
                      supported for MySQL and MSSQL databases.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      {" "}
                      What is File Change Alert Monitoring ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      CodeGuard can monitor and notify you by email when your
                      website changes to alert you to new threats and malware.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      {" "}
                      What happens if my site gets infected ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      With CodeGuard's daily snapshots, if your website gets
                      attacked, you can restore to a previous uninfected version
                      at any time.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</>

    </div>
  );
};

export default CodeguardBackup;
