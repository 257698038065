// src/pages/GoogleWorkspace.jsx
import React from 'react';
import google from '../assets/images/googleworkspace.png';
import communicate from '../assets/images/communicate.png';
import collab from '../assets/images/collaborate.png';
import manage from '../assets/images/manages.png';


const GoogleWorkspace = () => {
  return (
    <div>
      <>
  <div className="hero-banner bg4">
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">Google Workspace</h6>
            <h1>
              What's included with Google{" "}
              <span className="highlight">Workspace</span>{" "}
            </h1>
            <p className="big mb-3">
              Intelligent tools your business can depend on{" "}
            </p>
            <a className="theme-btn three width-increase" href="#plans">
              <i className="ri-eye-line start" /> View Plan
            </a>{" "}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12 d-none d-lg-block">
          {" "}
          <img src={google} alt="image" />{" "}
        </div>
      </div>
    </div>
  </div>
  <main>
    <section className="theme-section-gap tabs-style-one bg2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-12">
            <div className="theme-section-heading">
              <div className="tabings d-flex">
                <div className="tabs tabs-button column-type justify-content-center w-100">
                  <button
                    className="tabbutton active"
                    onclick="openTabings(event, 'Communicate')"
                  >
                    <i className="bi bi-database-fill" /> Effectively
                    Communicate{" "}
                  </button>
                  <button
                    className="tabbutton"
                    onclick="openTabings(event, 'Collaborate')"
                  >
                    <i className="bi bi-headset" /> Seamlessly Collaborate{" "}
                  </button>
                  <button
                    className="tabbutton"
                    onclick="openTabings(event, 'Manage')"
                  >
                    <i className="bi bi-boxes" /> Easily Manage{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="p-3">
              <div
                id="Communicate"
                className="tabscontents active"
                style={{ display: "block" }}
              >
                <div className="row justify-content-between align-items-center g-5">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="row gy-4">
                      <div className="col-12">
                        <div className="style-one-card align-items-start">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 512 512"
                              fill="currentcolor"
                            >
                              {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                              <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                            </svg>
                          </div>
                          <div className="theme-section-heading">
                            <h3>Get the power of Gmail</h3>
                            <p>
                              Get a professional email for your business with
                              all the powerful features of Gmail.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="style-one-card align-items-start">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 448 512"
                              fill="currentcolor"
                            >
                              {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                              <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z" />
                            </svg>
                          </div>
                          <div className="theme-section-heading">
                            <h3>Keep your schedule organized</h3>
                            <p>
                              Add reminders, schedule meetings and never miss a
                              thing with Google's smart calendar.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="style-one-card align-items-start">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 576 512"
                              fill="currentcolor"
                            >
                              {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                              <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z" />
                            </svg>
                          </div>
                          <div className="theme-section-heading">
                            <h3>Communicate on the go</h3>
                            <p>
                              Stay connected with your colleagues via HD video
                              calls and instant messages using Google Meet and
                              Google Chat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="feature_image">
                      {" "}
                      <img src={communicate} alt="image" />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="Collaborate"
                className="tabscontents"
                style={{ display: "none" }}
              >
                <div className="row justify-content-between align-items-center g-5 flex-lg-row-reverse">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="row gy-4">
                      <div className="col-12">
                        <div className="style-one-card align-items-start">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 512 512"
                              fill="currentcolor"
                            >
                              {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                              <path d="M339 314.9L175.4 32h161.2l163.6 282.9H339zm-137.5 23.6L120.9 480h310.5L512 338.5H201.5zM154.1 67.4L0 338.5 80.6 480 237 208.8 154.1 67.4z" />
                            </svg>
                          </div>
                          <div className="theme-section-heading">
                            <h3>Intelligent Collaboration</h3>
                            <p>
                              Collaborate in real time, store your files on the
                              cloud, share and access them from anywhere.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="style-one-card align-items-start">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 512 512"
                              fill="currentcolor"
                            >
                              {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                            </svg>
                          </div>
                          <div className="theme-section-heading">
                            <h3>Smart Cloud search</h3>
                            <p>
                              Search across all your organizations content. From
                              Gmail and Drive to Docs, Sheets, Slides, Calendar,
                              and more.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="style-one-card align-items-start">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 640 512"
                              fill="currentcolor"
                            >
                              {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                              <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z" />
                            </svg>
                          </div>
                          <div className="theme-section-heading">
                            <h3>Stay updated </h3>
                            <p>
                              Discuss new ideas, engage in meaningful
                              conversations and stay up to date with Currents.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="feature_image bg2">
                      {" "}
                      <img src={collab} alt="image" />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="Manage"
                className="tabscontents"
                style={{ display: "none" }}
              >
                <div className="row justify-content-between align-items-center g-5">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="row gy-4">
                      <div className="col-12">
                        <div className="style-one-card align-items-start">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 384 512"
                              fill="currentcolor"
                            >
                              {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                              <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                            </svg>
                          </div>
                          <div className="theme-section-heading">
                            <h3>Efficient control from a single place</h3>
                            <p>
                              Manage users, groups, permissions and migrate
                              existing accounts to Google Workspace easily from
                              the Admin Console.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="style-one-card align-items-start">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 640 512"
                              fill="currentcolor"
                            >
                              {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                              <path d="M150.6 502.6l96-96c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 402.7V288H416V272c0-17.2 3.9-33.5 10.8-48H352V109.3l41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96c-6-6-14.1-9.4-22.6-9.4s-16.6 3.4-22.6 9.4l-96 96c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L288 109.3V224l-128 0H96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32H96V402.7L54.6 361.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l96 96c12.5 12.5 32.8 12.5 45.3 0zM160 192V64c0-17.7-14.3-32-32-32s-32 14.3-32 32V192h64zM288 320V448c0 17.7 14.3 32 32 32s32-14.3 32-32V320H288zm240-80c17.7 0 32 14.3 32 32v48H496V272c0-17.7 14.3-32 32-32zm-80 32v48c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32V272c0-44.2-35.8-80-80-80s-80 35.8-80 80z" />
                            </svg>
                          </div>
                          <div className="theme-section-heading">
                            <h3> Archive your data with ease</h3>
                            <p>
                              Retain and manage how your organizations data is
                              stored and save what's important with Vault.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="style-one-card align-items-start">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 512 512"
                              fill="currentcolor"
                            >
                              {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                              <path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8V444.8C394 378 431.1 230.1 432 141.4L256 66.8l0 0z" />
                            </svg>
                          </div>
                          <div className="theme-section-heading">
                            <h3> Stay Secure</h3>
                            <p>
                              Manage and give access to your employees on their
                              devices while keeping your data secure with
                              Endpoint Management.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="feature_image bg-3">
                      {" "}
                      <img src={manage} alt="image" />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap plan-one bgone" id="plans">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="theme-section-heading text-center">
            <h2>
              Get Started with Google{" "}
              <span className="highlight">Workspace</span>
            </h2>
            <p>
              Choose a Google Workspace plan that serves your business
              requirement
            </p>
          </div>
        </div>
        <div className="row justify-content-between g-4 mt-4">
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Business Starter</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">9.59</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-3 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>2</span>&nbsp; CPU Cores
                  </li>
                  <li>
                    <span>2</span>&nbsp; GB SSD Disk Space
                  </li>
                  <li>
                    <span>20</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>1</span>&nbsp; Additional IP
                  </li>
                  <li>
                    <span>0</span>&nbsp; TB Bandwidth
                  </li>
                  <li>Free SSL Certificate</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Business Standard</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">17.59</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-3 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>2</span>&nbsp; CPU Cores
                  </li>
                  <li>
                    <span>4</span>&nbsp; GB SSD Disk Space
                  </li>
                  <li>
                    <span>40</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>1</span>&nbsp; Additional IP
                  </li>
                  <li>
                    <span>1</span>&nbsp; TB Bandwidth
                  </li>
                  <li>Free SSL Certificate</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card plan-top-head-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Business Plus</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">37.59</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-3 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>3</span>&nbsp; CPU Cores
                  </li>
                  <li>
                    <span>6</span>&nbsp; GB SSD Disk Space
                  </li>
                  <li>
                    <span>80</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>2</span>&nbsp; Additional IP
                  </li>
                  <li>
                    <span>1</span>&nbsp; TB Bandwidth
                  </li>
                  <li>Free SSL Certificate</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Enterprise Plus</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">63.59</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-3 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>4</span>&nbsp; CPU Cores
                  </li>
                  <li>
                    <span>8</span>&nbsp; GB SSD Disk Space
                  </li>
                  <li>
                    <span>120</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>2</span>&nbsp; Additional IP
                  </li>
                  <li>
                    <span>1</span>&nbsp; TB Bandwidth
                  </li>
                  <li>Free SSL Certificate</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap customer-support">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-12">
            <div className="bg2 customer-support-box">
              <div className="row justify-content-between g-5">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="theme-section-heading text-start">
                    <h6 className="style">Contact Us</h6>
                    <h2 className="mt-3">
                      Any query ? <span className="highlight">let's talk</span>
                    </h2>
                    <p>
                      Any questions or topics you'd like to discuss? Feel free
                      to share your thoughts, and let's engage in a
                      conversation. I'm here to help and chat with you.
                    </p>
                    <div className="theme-btn two mt-3">
                      {" "}
                      <a href="https://manage.hostingaviator.cloud/contact.php">Support Center</a>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-envelope-at-fill" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">Email us</span>{" "}
                      <a href="mailto:example@gmail.com">example@gmail.com</a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-phone-flip" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">call</span>{" "}
                      <a href="tel:1234567899">+91 123 456 7890</a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-geo-alt" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">Our location</span>{" "}
                      <a target="_blank" href="https://www.google.com/maps">
                        Open Google Maps
                      </a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
{/*

    <section className="testimonial theme-section-gap bg-secondary-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="theme-section-heading center">
              <h2>
                Hear from happy <span className="highlight">customers</span>
              </h2>
              <p>
                Our satisfied customers love our products/services. Read their
                glowing testimonials to discover why they choose us for quality
                and excellence.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="swiper testimonials">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Aaron Sweeney</h3>
                    <h4>Co-Founder</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Great
                      support, like i have never seen before. Thanks to the
                      support team, they are very helpfull. This company provide
                      customers great solution, that makes them best.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-2.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Animi
                      perferendis, commodi veritatis unde magnam magni? Eaque,
                      reprehenderit dignissimos?{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-3.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Simon Deubele</h3>
                    <h4>Team Member</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> I
                      chose you because you create added value over the price
                      offered by other hosting companies. it's been a great
                      experience for me. I'm leaving happy. Thank you.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>
    </section>
*/}

    <section className="faq theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center mb-4">
            <h2>
              Frequntly <b className="highlight heading">Asked </b> Question
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <div className="faq-qa">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {" "}
                      Can I use my existing domain with Google Workspace ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, you can use an existing domain with your Google
                      Workspace order.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {" "}
                      What happens to my existing mail, contacts, and calendar
                      data when I move to Google Workspace ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      When switching to Google Workspace from another program or
                      web service, you and your users can bring your existing
                      mail, contacts, and calendar data with you. You have a
                      variety of options for migrating data into Google
                      Workspace, depending on the size of your organization and
                      the system you're migrating from. Tools are available for
                      migration from Microsoft Exchange, Lotus Notes, IMAP
                      servers and other Google accounts.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      What is the space provided per Email Account ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Each email account comes with 30 GB space.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {" "}
                      Is Google Workspace compatible with the email client I use
                      today ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      In addition to accessing Google Workspace mail from the
                      Gmail web interface, you can send and receive mail from
                      your favorite desktop client. Depending on the client, you
                      can use either the IMAP or POP mail protocol. If you’re
                      switching to Google Workspace from Microsoft Exchange or
                      some other Outlook service, you can use Google Workspace
                      Sync. This is a plug-in for Outlook 2003, 2007, 2010 or
                      2013 that lets you use Outlook to manage your Google
                      Workspace mail, calendar and contacts—along with your
                      Outlook notes, tasks and journal entries.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {" "}
                      What is the space provided per Email Account ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Each email account comes with 5 GB space dedicated to
                      emails.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      {" "}
                      Can I transfer my existing Google Workspace ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes. During the transfer, we move all your email accounts
                      from the old provider to us by keeping the data intact.
                      However, your existing tenure with the other provider, if
                      any does not get moved to us. You can{" "}
                      <a href="#">click here</a> to initiate the transfer
                      process.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      {" "}
                      Can I create mailing lists ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, you can create mailing lists and add/delete users,
                      select a moderator, restrict people from joining a list or
                      even ban users from a list. More information on this can
                      be found in our knowledgebase.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      {" "}
                      What is your SPAM policy ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      We take a zero tolerance stance against sending of
                      unsolicited email, bulk emailing, and spam. "Safe lists",
                      purchased lists, and selling of lists will be treated as
                      spam. Any user who sends out spam will have their account
                      terminated with or without notice.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      {" "}
                      Can I use Auto Responders ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, you can. An auto-responder is a program that, when
                      setup for your email address, sends out an automatic,
                      pre-set reply to an email, as soon as it is received at
                      this email address. You can find out more about setting up
                      an auto-responder from our knowledge base.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</>

    </div>
  );
};

export default GoogleWorkspace;
