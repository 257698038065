// src/pages/ComboOffer.jsx
import React from 'react';
import performance from '../assets/images/performance.svg';

const ComboOffer = () => {
  return (
    <div>
      <>
  <div className="hero-banner bg1">
    <div className="container">
      <div className="row align-items-center justify-content-between">
        <div className="col-lg-6">
          <div className="inner-content">
            <h6 className="style">Combo Offer</h6>
            <h1>
              Ready-made plans for your{" "}
              <span className="highlight">every need</span>
            </h1>
            <p>
              Startup, Small Business or a Large Enterprise - Our Combo Offers
              have you covered.
            </p>
            <a className="theme-btn three width-increase" href="#plans">
              <i className="bi bi-hdd-stack start" /> View Plan
            </a>{" "}
          </div>
        </div>
        <div className="col-lg-6 d-none d-lg-block">
          {" "}
          <img src={performance} alt="image" />{" "}
        </div>
      </div>
    </div>
  </div>
  <main>
    <section className="combo-plan theme-section-gap bg2" id="plans">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="theme-section-heading text-center">
              <h2>
                We've made it <span className="highlight thirty">easy </span>{" "}
                for you!
              </h2>
              <p>Choose your bundle &amp; be on your way</p>
            </div>
          </div>
        </div>
        <div className="combo-offer-block mt-3">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="theme-section-heading text-center">
                <h2>
                  Basic Plan <span className="highlight"> Shared Hosting</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center g-5">
            <div className="col-lg-8 col-12">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <ul className="list-tick">
                    <li>Free Domain - 1 year</li>
                    <li>WordPress Integration</li>
                    <li>5 GB SSD Storage</li>
                    <li>Drag and Drop Functionality</li>
                  </ul>
                </div>
                <div className="col-lg-6 col-12">
                  <ul className="list-tick">
                    <li>Free CDN included</li>
                    <li>Free SSL Certificate</li>
                    <li>Undeterred Data Transfer</li>
                    <li>WordPress Optimized Environment</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="theme-section-heading justify-content-end">
                <div className="pricing-card">
                  <h3 className="mb-2">
                    <span className="highlight">
                      $11.76 <sub>/mo</sub>
                    </span>
                  </h3>
                  <a className="theme-btn three width-increase" href="#">
                    <i className="bi bi-hdd-stack start" /> Get Satrted Now
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="combo-offer-block mt-3">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="theme-section-heading text-center">
                <h2>
                  Advanced Plan <span className="highlight">VPS Hosting</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center g-5">
            <div className="col-lg-8 col-12">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <ul className="list-tick">
                    <li>Free Domain - 1 year</li>
                    <li>WordPress Integration</li>
                    <li>5 GB SSD Storage</li>
                    <li>Drag and Drop Functionality</li>
                  </ul>
                </div>
                <div className="col-lg-6 col-12">
                  <ul className="list-tick">
                    <li>Free CDN included</li>
                    <li>Free SSL Certificate</li>
                    <li>Undeterred Data Transfer</li>
                    <li>WordPress Optimized Environment</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="theme-section-heading justify-content-end">
                <div className="pricing-card">
                  <h3 className="mb-2">
                    <span className="highlight">
                      $43.56 <sub>/mo</sub>
                    </span>
                  </h3>
                  <a className="theme-btn three width-increase" href="#">
                    <i className="bi bi-hdd-stack start" /> Get Satrted Now
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="combo-offer-block mt-3">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="theme-section-heading text-center">
                <h2>
                  Enterprise <span className="highlight">Cloud </span>Servers
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center g-5">
            <div className="col-lg-8 col-12">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <ul className="list-tick">
                    <li>Free Domain - 1 year</li>
                    <li>WordPress Integration</li>
                    <li>5 GB SSD Storage</li>
                    <li>Drag and Drop Functionality</li>
                  </ul>
                </div>
                <div className="col-lg-6 col-12">
                  <ul className="list-tick">
                    <li>Free CDN included</li>
                    <li>Free SSL Certificate</li>
                    <li>Undeterred Data Transfer</li>
                    <li>WordPress Optimized Environment</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="theme-section-heading justify-content-end">
                <div className="pricing-card">
                  <h3 className="mb-2">
                    <span className="highlight">
                      $60.90 <sub>/mo</sub>
                    </span>
                  </h3>
                  <a className="theme-btn three width-increase" href="#">
                    <i className="bi bi-hdd-stack start" /> Get Satrted Now
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*

    <section className="testimonial theme-section-gap bg-secondary-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="theme-section-heading center">
              <h2>
                Hear from happy <span className="highlight">customers</span>
              </h2>
              <p>
                Our satisfied customers love our products/services. Read their
                glowing testimonials to discover why they choose us for quality
                and excellence.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="swiper testimonials">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Aaron Sweeney</h3>
                    <h4>Co-Founder</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Great
                      support, like i have never seen before. Thanks to the
                      support team, they are very helpfull. This company provide
                      customers great solution, that makes them best.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-2.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Animi
                      perferendis, commodi veritatis unde magnam magni? Eaque,
                      reprehenderit dignissimos?{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-3.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Simon Deubele</h3>
                    <h4>Team Member</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> I
                      chose you because you create added value over the price
                      offered by other hosting companies. it's been a great
                      experience for me. I'm leaving happy. Thank you.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>
    </section>
    */}
  </main>
</>

    </div>
  );
};

export default ComboOffer;
