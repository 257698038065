import React from "react";
import './footer.css';

// Importing all images used in the footer
import logoImage from '../assets/images/logo.png';
import trustImage from '../assets/images/turste.png';
import nortonImage from '../assets/images/norton.png';
import paymentImage from '../assets/images/payment.png';

const Footer = () => {
  return (
    <footer>
      <div className="main-footer">
        <div className="row g-md-4 g-3">
          <div className="col-xl-3 col-lg-4 col-sm-6">
            <div className="footer-logo">
              <a href="/">
                <img src={logoImage} alt="logo" />
              </a>
              <p style={{ position: 'relative', top: '10px', bottom: '10px', left: '15px', right: '15px' }}>
                Hosting Aviator is a dynamic and innovative web hosting and domain provider dedicated to serving the diverse needs of businesses and individuals in the digital landscape. Established in 2024 by its founder, Avinash Yadav, Hosting Aviator swiftly ascended to become a leading force in the web hosting industry, particularly within the Indian market.
              </p>
              <ul className="address">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                  </svg>
                  <a href="javascript:void(0)">Lucknow, Uttar Pradesh India</a>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>
                  <a href="javascript:void(0)">info@hostingaviator.cloud</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6">
            <div className="footer-list">
              <h5>Domains</h5>
              <ul className="links">
                <li><a href="/domain-register">Register Domain Name</a></li>
                <li><a href="#">Whois Lookup</a></li>
                <li><a href="#">Name Suggestion Tool</a></li>
                <li><a href="/combo-offer">Free with Every Domain</a></li>
              </ul>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6">
            <div className="footer-list">
              <h5>Useful Links</h5>
              <ul className="links">
                <li><a href="/about-us">About Us</a></li>
                <li><a href="my-team">My Team</a></li>
                <li><a href="privacy-policy">Privacy Policy</a></li>
                <li><a href="terms-condition">Terms And Condition</a></li>
                <li><a href="refund-policy">Refund Policy</a></li>
                <li><a href="blogwritting.info">Blog</a></li>
                <li><a href="#">Affiliate/Partner Links</a></li>
              </ul>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6">
            <div className="footer-list">
              <h5>Support</h5>
              <ul className="links">
                <li><a href="#">Datacenter Details</a></li>
                <li><a href="#">Hosting Security</a></li>
                <li><a href="#">24 x 7 Servers Monitoring</a></li>
                <li><a href="#">Backup and Recovery</a></li>
                <li><a target="_blank" href="https://manage.hostingaviator.cloud/index.php?rp=/knowledgebase">View Knowledge Base</a></li>
                <li><a href="https://manage.hostingaviator.cloud/contact.php">Contact Support</a></li>
                <li><a href="#">Report Abuse</a></li>
              </ul>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6">
            <div className="footer-list">
              <h5>Contact Us</h5>
              <ul className="footer-contact">
                <li>
                  <span className="footer-number">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                    </svg>
                    <span className="contact-number">
                      <span className="text-content">Hotline 24/7 :</span>
                      <span className="detail">+91 9696626959</span>
                    </span>
                  </span>
                </li>
                <li>
                  <span className="footer-number">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                    <span className="contact-number">
                      <span className="text-content">Email Address :</span>
                      <span className="detail">info@hostingaviator.cloud</span>
                    </span>
                  </span>
                </li>
                <li className="social-app mb-0">
                  <h5 className="mb-2 text-content">Security By :</h5>
                  <ul>
                    <li className="mb-0">
                      <a href="#" target="_blank">
                        <img src={trustImage} alt="security" />
                      </a>
                    </li>
                    <li className="mb-0">
                      <a href="#" target="_blank">
                        <img src={nortonImage} alt="security" />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <div className="reserve">
          <h6 className="sub-footer-content">©2024 HOSTING AVIATOR All rights reserved</h6>
        </div>
        <div className="payment">
          <img src={paymentImage} alt="payment" />
        </div>
        <div className="social-link">
          <h6 className="sub-footer-content">Stay connected :</h6>
          <ul>
            <li>
              <a href="#">
                <i className="bi bi-facebook"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-twitter"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-instagram"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-whatsapp"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
