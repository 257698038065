import React from 'react';
import './About.css';
import website from '../assets/images/privacy-policy.jpg'

const AboutUs = () => {
  return (
    <div>
      <div className="hero-banner bg1">
    <div className="container">
      <div className="row justify-content-between align-items-center g-5 ">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">Legal</h6>
            <h1 className="mb-3">
              Privacy  <span className="highlight">And Policy</span> 
            </h1>
            <p>
           
            We respect your privacy and safeguard your data. Information collected is used to provide and improve services. We don’t share personal data without consent, except for legal requirements. Cookies and analytics enhance user experience. By using our services, you agree to this policy, which may change periodically.            </p>
            
            {" "}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          {" "}
          <img src={website} alt="website" />{" "}
        </div>
      </div>
    </div>
  </div>
      <section className="theme-section-gap">
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="theme-section-heading text-center">
            <h2>
              <span className="highlight">Privacy</span> Policy
            </h2>
          </div>
        </div>
        <div className="row justify-content-stretch align-items-stretch g-100">
          <div className="col-lg- col-md-100 col-100">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-hdd-network" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-hdd-network"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a.5.5 0 0 1 0 1H10A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5H.5a.5.5 0 0 1 0-1H6A1.5 1.5 0 0 1 7.5 10V7H2a2 2 0 0 1-2-2V4zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1zm6 7.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5z" />
                </svg>
              </div>
              <div className="card-content">
                 
                <p>
                <h3> 
                <h6>Collection of Information</h6>
                        <p class="mb-3">We collect various information when you register with Company, during your usage
                            of our products and services, when you visit pages of our partners. We may combine
                            information about you that we have with information we obtain from other partners or other
                            companies in order to provide you a better experience or to improve the quality of our
                            services. When you sign in to our services, you are not anonymous to us. We automatically
                            receive and record information from your computer and browser, including your IP address,
                            our cookie information, software and hardware attributes, the page you request and other
                            standard browser parameters.</p>

                        <p class="mb-3">When you use our services, we send one or more cookies - a small file containing
                            a string of characters - to your device that uniquely identifies your browser. We use
                            cookies to improve the quality of our service, including for storing user preferences,
                            tracking user trends, and providing you with a better experience. We may set one or more
                            cookies in your browser when you use our services or one of our partner's services. When you
                            send messages, publish content, post material, transmit information or email through our
                            services, we may retain the same in order to process your inquiries, respond to your
                            requests and improve our services. </p>
                        <p class="mb-3 border-bottom pb-3">Our infrastructure consists of servers deployed at multiple
                            datacenters that are owned and operated by us or our partners. All information collected
                            within our services resides on this network of servers.</p>

                        <h6>Use of Information</h6>

                        <p class="mb-3">We only use and process personal information for the purposes described in this
                            Privacy Policy. In addition to the above, such purposes include:</p>

                        <ul class="list-tick border-bottom pb-3 mb-3">
                            <li>Communicating with you</li>
                            <li>Making the sites or services easier to use by eliminating the need for you to repeatedly
                                enter the same information</li>
                            <li>Providing our services</li>
                            <li>Auditing, research and analysis in order to maintain, protect and improve our services
                            </li>
                            <li>Ensuring the technical functioning of our network</li>
                            <li>Ensuring the technical functioning of our network</li>
                            <li>Protecting our rights or property and that of our users</li>
                            <li>Developing new services</li>
                            <li>As described in the respective terms of service of each of our services and in the
                                Redcheap Theme Customer Master Agreement</li>
                        </ul>

                        <h6>No-Spam Policy </h6>
                        <p class="mb-3 border-bottom pb-3">We have a strict No-Spam Policy prohibiting the use of any
                            data collected to send spam. We will not sell any contact information to third parties.</p>

                        <h6>Choices for Personal Information</h6>

                        <p class="mb-3 border-bottom pb-3">When you sign up for a particular service that requires
                            registration, we ask you to provide personal information. If we use this information in a
                            manner different than the purpose defined herein and in the terms of the services offered
                            then we will ask for your consent prior to such use. Most browsers are initially set up to
                            accept cookies, but you can reset your browser to refuse all cookies or to indicate when a
                            cookie is being sent. However, some of our features and services may not function properly
                            if your cookies are disabled. You can decline to submit personal information to any of our
                            services, in which case we may not be able to provide those services to you.</p>

                        <h6>Information Sharing</h6>
                        <p class="mb-3">We only share personal information with other companies or individuals in the
                            following limited circumstances:</p>

                        <ul class="list-tick border-bottom pb-3 mb-3">
                            <li>We have your consent</li>
                            <li>Sharing the information with our subsidiaries, affiliated companies or other trusted
                                businesses or persons for the purpose of processing personal information on our behalf.
                                We require that these parties agree to process such information based on our
                                instructions and in compliance with this Privacy Policy and any other appropriate
                                confidentiality and security measures.</li>
                            <li>We have a good faith belief that access, use, preservation or disclosure of such
                                information is reasonably necessary to:
                                (a) satisfy any applicable law, regulation, legal process or enforceable governmental
                                request,
                                (b) enforce applicable Terms of Service, including investigation of potential violations
                                thereof,
                                (c) detect, prevent, or otherwise address fraud, security or technical issues, or
                                (d) protect against harm to rights, property or safety, of our users, us or the public
                                as required or permitted by law.</li>
                            <li>We may share with third parties certain pieces of aggregated, non-personal information,
                                such as the number of users by demographic. Such information does not identify you
                                individually.</li>
                            <li>As permitted and described in the respective terms of service of each of our services
                                and in the Redcheap Theme Customer Master Agreement</li>
                        </ul>

                        <h6>Information Security</h6>

                        <p class="mb-3">We take appropriate security measures to protect against unauthorized access to
                            or unauthorized alteration, disclosure or destruction of data. These include internal
                            reviews of our data collection, storage and processing practices and security measures, as
                            well as physical security measures to guard against unauthorized access to systems where we
                            store personal data.</p>

                        <p class="mb-3 border-bottom pb-3">We restrict access to personal information to our employees,
                            contractors and agents who need to know that information in order to operate, develop or
                            improve our services. These individuals are bound by confidentiality obligations and may be
                            subject to discipline, including termination and criminal prosecution, if they fail to meet
                            these obligations.</p>

                        <h6>Accessing and Updating Personal Information</h6>

                        <p class="mb-3 border-bottom pb-3">When you use our services, we make good faith efforts to
                            provide you with access to your personal information and to correct this data if it is
                            inaccurate.</p>

                        <h6>Changes to this Privacy Policy</h6>
                        <p>Please note that this Privacy Policy may change from time to time.</p>
                </h3>
             </p>
                {" "}
              </div>
            </div>
          </div>
          
          

          


          

        
        </div>
      </div>
    </section>
    </div>
  );
};

export default AboutUs;
