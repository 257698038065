// src/pages/AcronisBackup.jsx
import React from 'react';
import ac from '../assets/images/acronis.jpg';
import invoice from '../assets/images/invoice.svg';
import ease from '../assets/images/ease.png'
import dashboard from '../assets/images/dashboard.png';
import security from '../assets/images/security.png';

const AcronisBackup = () => {
  return (
    <div>
      <>
  <div className="hero-banner bg1">
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">Acronis</h6>
            <h1 className="mb-3">
              Acronis Cyber <span className="highlight">Backup </span>
            </h1>
            <p>Keep Your Data Safe on the Cloud</p>
            <ul className="banner-list mt-3 mb-3">
              <li>Never lose any data</li>
              <li>One-click setup</li>
              <li>Simplified Pricing</li>
            </ul>
            <div className="mt-4 mb-4">
              <h3>
                from Just <sup>$</sup>12.76 <sub>/mo</sub>
              </h3>
            </div>
            <a className="theme-btn three width-increase mt-3" href="#">
              <i className="ri-eye-line start" /> Get started Now
            </a>{" "}
          </div>
        </div>
        <div className="col-lg-5 col-md-6 col-12 d-none d-lg-block">
          {" "}
          <img
            src={ac}
            alt="images6"
            className="mix-dark"
          />{" "}
        </div>
      </div>
    </div>
  </div>
  <main>
    <section className="theme-section-gap bg2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-12">
            <div className="tabs-style-one">
              <div className="tabings d-flex">
                <div className="tabs tabs-button column-type justify-content-center w-100">
                  <button
                    className="tabbutton active"
                    onclick="openTabings(event, 'Pricing')"
                  >
                    <i className="ri-bill-line" /> Simple Pricing{" "}
                  </button>
                  <button
                    className="tabbutton"
                    onclick="openTabings(event, 'Ease')"
                  >
                    <i className="ri-booklet-line" /> Ease of use{" "}
                  </button>
                  <button
                    className="tabbutton"
                    onclick="openTabings(event, 'Intuitive')"
                  >
                    <i className="ri-window-2-fill" /> Intuitive dashboard{" "}
                  </button>
                  <button
                    className="tabbutton"
                    onclick="openTabings(event, ' Security')"
                  >
                    <i className="ri-git-repository-private-line" /> Security{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="p-3">
              <div
                id="Pricing"
                className="tabscontents active"
                style={{ display: "block" }}
              >
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-5 col-12">
                    <h4>No bill shocks, no headaches</h4>
                    <ul className="list-tick mt-3">
                      <li>Simplified prepaid pricing</li>
                      <li>Pay only for the backup space needed</li>
                      <li>No additional costs for backups or restores</li>
                      <li>Increase the backup storage anytime you need</li>
                    </ul>
                  </div>
                  <div className="col-lg-5 d-none d-lg-block">
                    {" "}
                    <img
                      src={invoice}
                      alt="image"
                      className="d-block m-auto"
                    />{" "}
                  </div>
                </div>
              </div>
              <div
                id="Ease"
                className="tabscontents"
                style={{ display: "none" }}
              >
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-5 col-12">
                    <h4>Eliminates all manual tasks</h4>
                    <ul className="list-tick mt-3">
                      <li>Automatic setup of backup account</li>
                      <li>Schedule automatic and regular backups</li>
                      <li>
                        Restore to same server or another server with a few
                        clicks
                      </li>
                      <li>Fast backups and restores</li>
                    </ul>
                  </div>
                  <div className="col-lg-5 d-none d-lg-block">
                    {" "}
                    <img
                      src={ease}
                      alt="image"
                      className="d-block m-auto"
                    />{" "}
                  </div>
                </div>
              </div>
              <div
                id="Intuitive"
                className="tabscontents"
                style={{ display: "none" }}
              >
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-5 col-12">
                    <h4>All the intelligence on one panel</h4>
                    <ul className="list-tick mt-3">
                      <li>
                        Manage all your VPS and Dedicated servers from one panel
                      </li>
                      <li>Check health of all your backup processes</li>
                      <li>Stay updated with alerts and notifications</li>
                      <li>Automated email alerts</li>
                    </ul>
                  </div>
                  <div className="col-lg-5 d-none d-lg-block">
                    {" "}
                    <img
                      src={dashboard}
                      alt="image"
                      className="d-block m-auto"
                    />{" "}
                  </div>
                </div>
              </div>
              <div
                id="Security"
                className="tabscontents"
                style={{ display: "none" }}
              >
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-5 col-12">
                    <h4>Peace of mind Security</h4>
                    <ul className="list-tick mt-3">
                      <li>Off-site backups to ensure data redundancy</li>
                      <li>Ransomware protection</li>
                      <li>Malware protection</li>
                      <li>Vulnerability assessment</li>
                    </ul>
                  </div>
                  <div className="col-lg-5 d-none d-lg-block">
                    {" "}
                    <img
                      src={security}
                      alt="image"
                      className="d-block m-auto"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap">
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="theme-section-heading text-center">
            <h2>
              <span className="highlight">Compatible</span> With
            </h2>
          </div>
        </div>
        <div className="row justify-content-center align-items-stretch g-5">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-hdd-network" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-hdd-network"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a.5.5 0 0 1 0 1H10A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5H.5a.5.5 0 0 1 0-1H6A1.5 1.5 0 0 1 7.5 10V7H2a2 2 0 0 1-2-2V4zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1zm6 7.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5z" />
                </svg>
              </div>
              <div className="card-content">
                <h3> VPS Servers</h3>
                <p>
                  VPS (Virtual Private Server) offers scalable hosting with
                  dedicated resources, granting flexibility, control, and
                  performance for websites and applications.
                </p>
                <a className="theme-btn two mt-2" href="#">
                  Get Staterd
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-journals" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-journals"
                  viewBox="0 0 16 16"
                >
                  <path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2z" />
                  <path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>Dedicated Servers</h3>
                <p>
                  VPS (Virtual Private Server) offers scalable hosting with
                  dedicated resources, granting flexibility, control, and
                  performance for websites and applications.
                </p>
                <a className="theme-btn two mt-2" href="#">
                  Get Staterd
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-windows" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-windows"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.555 1.375 0 2.237v5.45h6.555V1.375zM0 13.795l6.555.933V8.313H0v5.482zm7.278-5.4.026 6.378L16 16V8.395H7.278zM16 0 7.33 1.244v6.414H16V0z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>Windows Dedicated Servers</h3>
                <p>
                  VPS (Virtual Private Server) offers scalable hosting with
                  dedicated resources, granting flexibility, control, and
                  performance for websites and applications.
                </p>
                <a className="theme-btn two mt-2" href="#">
                  Get Staterd
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
{/*

    <section className="testimonial theme-section-gap bg-secondary-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="theme-section-heading center">
              <h2>
                Hear from happy <span className="highlight">customers</span>
              </h2>
              <p>
                Our satisfied customers love our products/services. Read their
                glowing testimonials to discover why they choose us for quality
                and excellence.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="swiper testimonials">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Aaron Sweeney</h3>
                    <h4>Co-Founder</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Great
                      support, like i have never seen before. Thanks to the
                      support team, they are very helpfull. This company provide
                      customers great solution, that makes them best.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-2.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Animi
                      perferendis, commodi veritatis unde magnam magni? Eaque,
                      reprehenderit dignissimos?{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-3.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Simon Deubele</h3>
                    <h4>Team Member</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> I
                      chose you because you create added value over the price
                      offered by other hosting companies. it's been a great
                      experience for me. I'm leaving happy. Thank you.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>
    </section>
    */}
    <section className="faq theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center mb-4">
            <h2>
              Frequntly <b className="highlight heading">Asked </b> Question
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <div className="faq-qa">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {" "}
                      Which products can I backup using Acronis Cyber Backup ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Acronis Cyber Backup can be configured to backup KVM Linux
                      VPS, Dedicated Linux Servers, and Dedicated Windows
                      Servers.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {" "}
                      Can I backup all my servers to the same Acronis Account ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes all your servers from one server location can be
                      backed up under the same Acronis Account. Servers from US
                      will be backed under the US account and servers from India
                      and Hong Kong will be backed up on your Acronis Asia
                      account Will the Acronis Cyber Backup order get renewed if
                      I renew my VPS or Dedicated server?{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      Will the Acronis Cyber Backup order get renewed if I renew
                      my VPS or Dedicated server ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      No. Acronis has its own product lifecycle and will not be
                      automatically renewed with your VPS or Dedicated Server.
                      You will have to renew it separately.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {" "}
                      How can I upgrade my backup storage space ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      If you need more backup storage space you can go to the
                      order management page and add more storage. Learn more
                      about adding storage.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {" "}
                      How safe is my backup data ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      All the data that you backup will be stored in an offsite
                      location, so that in case of a disaster at the location of
                      your VPS or Dedicated Server, your backup data will remain
                      safe. Additionally, the backup data is encrypted and
                      regularly scanned for malware to ensure complete
                      protection.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      {" "}
                      Can I restore backup data from one server to another ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes you can backup and restore your data from one server
                      to another provided you have the backup agent installed on
                      the servers.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      {" "}
                      Can I download the backup files to my local machine ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes you can download the backup files to your computer /
                      local machine from the Acronis panel. What happens when my
                      backup order expires?{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      {" "}
                      What happens when my backup order expires ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      When your backup order expires, it will be suspended and
                      you can renew the order within 15 days to retain your
                      backups. However, if you fail to renew within the time
                      period, your order will be deleted and the backups will
                      also be deleted.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</>

    </div>
  );
};

export default AcronisBackup;
