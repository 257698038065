// src/pages/DomainTransfer.jsx
import React from 'react';
import './homme.css';
import img1 from '../assets/images/icon1.png';
import img2 from '../assets/images/icon2.png';
import img3 from '../assets/images/icon3.png';
import img4 from '../assets/images/icon4.png';
import testimonialImg from '../assets/images/testimonilas.jpg';
import featureImg from '../assets/images/2.png';
const DomainTransfer = () => {
  return (
    <>
  <div className="hero-banner bg3 darknesss">
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">Transfer</h6>
            <h1 className="text-white">
              Domain Name <span className="highlight">Transfer</span>{" "}
            </h1>
            <p className="text-white">
              Browse our step-by-step guide for domain transfers to ensure a
              quick and easy transfer.{" "}
            </p>
            <form
              className="mt-3 domaintrans-form"
              method="post"
              action="https://manage.hostingaviator.cloud/cart.php?a=add&domain=transfer"
            >
              <input
                type="search"
                name="sld"
                className="w-100 form-radius"
                placeholder="your website"
              />
              <select className="form-select mb-10" name="tld">
                <option> .net </option>
                <option> .com </option>
                <option> .in </option>
              </select>
              <button className="theme-btn two width-increase" type="submit">
                {" "}
                Transfer Now{" "}
              </button>
            </form>
          </div>
        </div>
        <div className="col-lg-5 d-none d-lg-block">
          <div className="feature_image bg3">
            {" "}
            <img src={featureImg} alt="bg" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <main>
    <section className="theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading center">
            <h2>
              FREE Add-ons with every <span className="highlight">Domain</span>{" "}
              Name!
            </h2>
          </div>
        </div>
        <div className="row justify-content-between g-4 align-item-stretch mt-4">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-envelope-open"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882l-6-3.2ZM15 7.383l-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2Z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Free <span className="highlight">Email</span> Account
                </h3>
                <p>
                  Receive 2 personalized Email Addresses such as mail@
                  yourdomain.com with free fraud, spam and virus protection.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-globe2"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Domain <span className="highlight">Forwarding</span>
                </h3>
                <p>
                  Point your domain name to another website for free! Redirect
                  users when they type your domain name into a browser
                  (with/without domain masking &amp; SEO){" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-tv"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  <span className="highlight">DNS</span> Management
                </h3>
                <p>
                  Free lifetime DNS service which allows you to manage your DNS
                  records on our globally distributed and highly redundant DNS
                  infrastructure.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-globe-americas"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Domain Theft <span className="highlight">Protection</span>{" "}
                </h3>
                <p>
                  Protect your Domain from being transferred out accidentally or
                  without your permission with our free Domain Theft Protection.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-inboxes"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562A.5.5 0 0 0 1.884 9h12.234a.5.5 0 0 0 .496-.438L14.933 6zM3.809.563A1.5 1.5 0 0 1 4.981 0h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393zm.941.83.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438l.32-2.562H10.45a2.5 2.5 0 0 1-4.9 0H1.066z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Free Mail <span className="highlight">Forwards</span>{" "}
                </h3>
                <p>
                  Create free email forwards and automatically redirect your
                  email to existing email accounts.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-tools"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  Easy to use <span className="highlight">Control</span> Panel
                </h3>
                <p>
                  Use our intuitive Control Panel to manage your domain name,
                  configure email accounts, renew your domain name and buy more
                  services.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="style-one-card align-items-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-gear"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                </svg>
              </div>
              <div className="theme-section-heading">
                <h3>
                  {" "}
                  <span className="highlight">Bulk </span> Tools
                </h3>
                <p>
                  Easy-to-use bulk tools to help you Register, Renew, Transfer
                  and make other changes to several Domain Names in a single
                  step.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap customer-support">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-12">
            <div className="bg2 customer-support-box">
              <div className="row justify-content-between g-5">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="theme-section-heading text-start">
                    <h6 className="style">Contact Us</h6>
                    <h2 className="mt-3">
                      Any query ? <span className="highlight">let's talk</span>
                    </h2>
                    <p>
                      Any questions or topics you'd like to discuss? Feel free
                      to share your thoughts, and let's engage in a
                      conversation. I'm here to help and chat with you.
                    </p>
                    <a className="theme-btn two mt-3" href="https://manage.hostingaviator.cloud/contact.php">
                      Support Center
                    </a>{" "}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                        <div className="customer-support-text text-center">
                          <div>
                            <span className="icon"><i className="bi bi-envelope-at-fill" /></span>
                          </div>
                          <div className="text-start cus-email">
                            <span className="lable">Email us</span>
                            <a href="mailto:support@hostingaviator.cloud">support@hostingaviator.cloud</a>
                          </div>
                          <div className="icon-2"><i className="bi bi-arrow-right-circle-fill" /></div>
                        </div>
                        <div className="customer-support-text text-center">
                          <div>
                            <span className="icon"><i className="bi bi-phone-flip" /></span>
                          </div>
                          <div className="text-start cus-email">
                            <span className="lable">Call</span>
                            <a href="tel:9696626959">+91 9696626959</a>
                          </div>
                          <div className="icon-2"><i className="bi bi-arrow-right-circle-fill" /></div>
                        </div>
                        
                        <div className="customer-support-text text-center">
                          <div>
                            <span className="icon"><i className="bi bi-geo-alt" /></span>
                          </div>
                          <div className="text-start cus-email">
                            <span className="lable">Our location</span>
                            <a target="_blank" href="#">Open Google Maps</a>
                          </div>
                          <div className="icon-2"><i className="bi bi-arrow-right-circle-fill" /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
    <section className="theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center mb-4">
            <h2>
              Transferring a domain to{" "}
              <span className="highlight">HOSTING AVIATOR</span> is simple.
            </h2>
            <p>
              Browse our step-by-step guide for domain transfers to ensure a
              quick and easy transfer.{" "}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 featues-four">
            <div className="row featues-four-items">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="cards">
                  <div className="icon">
                    {" "}
                    <img src={img1} alt="bg" />
                  </div>
                  <div className="heading"> Step 1 </div>
                  <div className="content">
                    {" "}
                    Unlock the domain you want to transfer at your current
                    registrar.{" "}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="cards">
                  <div className="icon">
                    {" "}
                    <img src={img2} alt="icon2" />{" "}
                  </div>
                  <div className="heading"> Step 2 </div>
                  <div className="content">
                    {" "}
                    Search for your domain using the Hosting Aviator transfer tool.{" "}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="cards">
                  <div className="icon">
                    {" "}
                    <img src={img3} alt="icon3" />{" "}
                  </div>
                  <div className="heading"> Step 3 </div>
                  <div className="content">
                    {" "}
                    Enter your authorization code to confirm the transfer.{" "}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="cards">
                  <div className="icon">
                    {" "}
                    <img src={img4} alt="icon4" />{" "}
                  </div>
                  <div className="heading"> Step 4 </div>
                  <div className="content">
                    {" "}
                    Most domain transfers complete in 5-7 days.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="testimonial theme-section-gap bg-secondary-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="theme-section-heading center">
              <h2>
                Hear from happy <span className="highlight">customers</span>
              </h2>
              <p>
                Our satisfied customers love our products/services. Read their
                glowing testimonials to discover why they choose us for quality
                and excellence.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="swiper testimonials">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img src={testimonialImg} alt="testimonial" 
                      className="testimonial-img"
                      
                    />
                    <h3>Raj Sharma </h3>
                    <h4></h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Great
                      support, like i have never seen before. Thanks to the
                      support team, they are very helpfull. This company provide
                      customers great solution, that makes them best.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img src={featureImg} alt="feature" 
                      className="testimonial-img"
                      
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> {" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img src={testimonialImg} alt="testimonial"
                      className="testimonial-img"
                      
                    />
                    <h3>Simon Deubele</h3>
                    <h4>Team Member</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> I
                      chose you because you create added value over the price
                      offered by other hosting companies. it's been a great
                      experience for me. I'm leaving happy. Thank you.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="faq theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center mb-4">
            <h2>
              Frequntly <b className="highlight heading">Asked </b> Question
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <div className="faq-qa">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {" "}
                      Why should I transfer my domains to Hosting Aviator ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        {" "}
                        Some of the top benefits of transferring your domains to
                      </p>
                      <ul className="faq-list-tick mt-3">
                        <li>
                          You're charged a flat fee per domain, so you'll pay
                          nothing beyond the initial cost and ICANN
                          registration, where appropriate.
                        </li>
                        <li>
                          Keep any time left on your current registrations — and
                          throw in a free year of registration for each
                          transfer.** This could add up to significant savings.
                        </li>
                        <li>
                          Worldwide support wherever you are, so you can always
                          speak to a domains expert quickly and resolve issues
                          fast.
                        </li>
                        <li>
                          Bulk discounts of up to 31% off when you register or
                          transfer more than five .com domains at the same time.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {" "}
                      How do I transfer my domains to Hosting Aviator ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        {" "}
                        Transferring domains to Hosting Aviator is simple. Follow these
                        directions or{" "}
                        <a href="#">
                          watch our step-by-step video, where our Hosting Aviator Guides
                          lead you through the process.
                        </a>{" "}
                      </p>
                      <ul className="faq-list-tick mt-3">
                        <li>
                          Unlock the domain name you'd like to transfer with
                          your current registrar.
                        </li>
                        <li>
                          Get an authorization (also known as an EPP code or
                          transfer key) from your current registrar. This isn't
                          necessary in all cases, such as when transferring
                          country-code domain (ccTLDs). If the authorization
                          code isn't shown in your account manager, ask your
                          current registrar to email them to you.
                        </li>
                        <li>
                          Verify contact information on your domain name. The
                          administrator's contact information may be used by
                          your current registrar during the transfer process.
                        </li>
                      </ul>
                      <p className="mt-3">
                        You can transfer up to 500 of your domains to Zhost at
                        once in our{" "}
                      </p>
                      <a href="#">bulk domain transfer tool.</a>
                      <p className="mt-3">
                        If the extension you’d like to transfer isn’t listed,
                        you can’t transfer that domain name to us. Please note
                        there are special instructions for
                      </p>
                      <a href="#">
                        transferring country-code domains to Zhost.
                      </a>{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      What happens when I transfer a domain ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      When you transfer a domain, only the domain moves to
                      Zhost. Other services you may have that interact with the
                      domain, like email or hosting, do not, unless you decide
                      to move them to Zhost.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {" "}
                      Are there any limitations when transferring domains ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Domains can be transferred almost any time. However, ICANN
                      prohibits domain transfers within 60 days of registration
                      or prior transfer (.au excepted). ICANN policy.{" "}
                      <b>Please note</b> there are special instructions for
                      transferring .uk, .ca, .eu, .es, .se and .au domain names
                      to Hosting Aviator.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {" "}
                      How long does a domain transfer take ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        {" "}
                        Domain transfers usually take 5 to 7 days to complete.
                        Track the progress of your transfers by following these
                        steps:
                      </p>
                      <ul className="faq-list-tick mt-3">
                        <li>Log in to your Hosting Aviator Transfers Page.</li>
                        <li>
                          Select Transfers In if the domain is moving to your
                          account. Or, select Transfers Out if the domain is
                          moving from your account.
                        </li>
                        <li>
                          Select the different tabs to review the details for
                          domains in a particular status. Or, search for your
                          domain in the search bar.
                        </li>
                        <li>
                          Some statuses require you to take action, and that'll
                          show next to the domain. Check out our guide on domain
                          statuses to help you move forward with your transfer.
                        </li>
                      </ul>
                      <p className="mt-3">
                        You'll see confirmation that your changes have been
                        accepted.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      {" "}
                      How much does it cost to transfer my domain name ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Transferring a domain to Zhost costs a flat fee of ₹
                      499.00*, and you get a free year of registration. Upon the
                      expiration of your domain, you will be charged annually to
                      maintain the domain. The price is the then-current annual
                      retail price applicable to the domain.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</>

  );
};

export default DomainTransfer;
