import React from 'react';
import './About.css';
import website from '../assets/images/term.jpg'

const AboutUs = () => {
  return (
    <div>
      <div className="hero-banner bg1">
    <div className="container">
      <div className="row justify-content-between align-items-center g-5 ">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">legal</h6>
            <h1 className="mb-3">
              Terms  <span className="highlight">And Conditions</span> 
            </h1>
            <p>
            By using our services, you agree to these terms. Users must provide accurate details, avoid prohibited activities, and use services lawfully. Content belongs to the company and can’t be reused without consent. Payments are non-refundable unless stated. We protect user data per our Privacy Policy and may update these terms anytime.{" "}
            </p>
            
            {" "}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          {" "}
          <img src={website} alt="website" />{" "}
        </div>
      </div>
    </div>
  </div>
      <section className="theme-section-gap">
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="theme-section-heading text-center">
            <h2>
              <span className="highlight">Terms</span> And Conditions
            </h2>
          </div>
        </div>
        <div className="row justify-content-stretch align-items-stretch g-100">
          <div className="col-lg- col-md-100 col-100">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-hdd-network" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-hdd-network"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a.5.5 0 0 1 0 1H10A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5H.5a.5.5 0 0 1 0-1H6A1.5 1.5 0 0 1 7.5 10V7H2a2 2 0 0 1-2-2V4zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1zm6 7.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5z" />
                </svg>
              </div>
              <div className="card-content">
                 
                <p>
                <h3> By using the services provided by Hosting Aviator ("us," "we," or "our"), you agree to comply with and be bound by these Terms and Conditions.

You agree not to engage in any illegal or unauthorized activities while using Hosting Aviator's services. Prohibited activities include but are not limited to illegal content, harmful content, intellectual property infringement, malicious activities, and unsolicited communications.

Hosting Aviator reserves the right to remove or disable access to any content that violates these Terms or any applicable laws without notice.

We reserve the right to terminate or suspend your access to our services at our discretion, with or without cause, and without prior notice.

Hosting Aviator provides its services on an "as is" and "as available" basis, without any warranties, express or implied. Hosting Aviator shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages.

These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes shall be resolved exclusively in the courts of [Your Jurisdiction].

Hosting Aviator reserves the right to modify these Terms at any time. Your continued use of our services after the posting of changes constitutes your acceptance of the modified Terms. </h3>
             </p>
                {" "}
              </div>
            </div>
          </div>
          
          

          


          

        
        </div>
      </div>
    </section>
    </div>
  );
};

export default AboutUs;
