// src/pages/SslCertificates.jsx
import React from 'react';
import ssl from '../assets/images/ssl.png';

const SslCertificates = () => {
  return (
    <div>
      <>
  <div className="hero-banner bg5">
    <div className="container">
      <div className="row justify-content-between align-items-center gy-lg-4">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">SSl Certificate</h6>
            <h1>
              Secure your website and{" "}
              <span className="highlight">customer</span> data{" "}
            </h1>
            <p>
              SSL Certificates protect your customer's personal data including
              passwords, credit cards and identity information. Getting an SSL
              certificate is the easiest way to increase your customer's
              confidence in your online business.
            </p>
            <a className="theme-btn one width-increase mt-3" href="#plans">
              <i className="ri-search-2-line start" /> View Plan
            </a>{" "}
          </div>
        </div>
        <div className="col-lg-5 col-md-6 col-12">
          {" "}
          <img src={ssl} alt="ssl" />{" "}
        </div>
      </div>
    </div>
  </div>
  <main>
    <div className="theme-section-gap">
      <div className="container">
        <div className="roundness p-3 py-4 bgtwo">
          <div className="row justify-content-center">
            <div className="theme-section-heading text-center">
              <h2>
                Why buy an SSL <span className="highlight">certificate</span>
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Perferendis, asperiores.
              </p>
            </div>
          </div>
          <div className="row justify-content-center align-items-center mt-3 g-4 g-lg-0">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="service-style-two-card">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                    <path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8V444.8C394 378 431.1 230.1 432 141.4L256 66.8l0 0z" />
                  </svg>
                </div>
                <div className="card-content">
                  <h3>Rock-solid security</h3>
                  <p>
                    Comodo's SSL certificates provide upto 128 or 256-bit
                    encryption for maximum security of your website visitors'
                    data
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="service-style-two-card">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 640 512"
                  >
                    {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                    <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                  </svg>
                </div>
                <div className="card-content">
                  <h3>Boost customer confidence</h3>
                  <p>
                    Many customers actively look for the SSL lock icon before
                    handing over sensitive data. Get an SSL certificate to
                    increase your customer's trust in your online business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="service-style-two-card">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                    <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
                  </svg>
                </div>
                <div className="card-content">
                  <h3>Better SEO rankings</h3>
                  <p>
                    Google gives higher rankings to websites secured with SSL
                    certificates. Which means SSL certificates are critical if
                    you're serious about your online business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="service-style-two-card">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                    <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                  </svg>
                </div>
                <div className="card-content">
                  <h3>Comodo Secure Seal</h3>
                  <p>
                    Your certificate comes with a Comodo Secure Seal that serves
                    as a constant reminder to customers that your site is
                    protected
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="service-style-two-card">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                    <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
                  </svg>
                </div>
                <div className="card-content">
                  <h3>30-day money back guarantee</h3>
                  <p>
                    All our SSL certificates come with a 30-day Money Back
                    Guarantee. No questions asked.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="theme-section-gap plan-one" id="plans">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="theme-section-heading text-center">
            <h2>
              Set up your Select a{" "}
              <span className="highlight"> certificate </span> that works for
              you{" "}
            </h2>
          </div>
        </div>
        <div className="row justify-content-between align-item-stretch g-4 mt-4">
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="text-start">Positive SSL</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">19.95</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>Domain-validation</li>
                  <li>
                    <span>1</span>&nbsp; domain
                  </li>
                  <li>
                    <span>5</span>&nbsp; GB Storage
                  </li>
                  <li>Free additional server licenses</li>
                  <li>Issued within 2 days</li>
                  <li>$10,000 relying party warranty </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="text-start">Comodo SSL</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">32.17</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>Domain-validation</li>
                  <li>
                    <span>1</span>&nbsp; domain
                  </li>
                  <li>
                    <span>5</span>&nbsp; GB Storage
                  </li>
                  <li>Free additional server licenses</li>
                  <li>Issued within 2 days</li>
                  <li>$2,50,000 relying party warranty </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="text-start">Positive SSL Wildcard</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">54.65</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>Domain-validation</li>
                  <li>Unlimited sub-domains</li>
                  <li>Free additional server licenses</li>
                  <li>Issued within 2 days</li>
                  <li>$10,000 relying party warranty</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="text-start">Standard</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">57.98</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>Extended-validation</li>
                  <li>
                    <span>1</span>&nbsp; domain
                  </li>
                  <li>Trusted Green address bar</li>
                  <li>$1,750,000 relying party warranty</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap customer-support">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-12">
            <div className="bg2 customer-support-box">
              <div className="row justify-content-between g-5">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="theme-section-heading text-start">
                    <h6 className="style">Contact Us</h6>
                    <h2 className="mt-3">
                      Any query ? <span className="highlight">let's talk</span>
                    </h2>
                    <p>
                      Any questions or topics you'd like to discuss? Feel free
                      to share your thoughts, and let's engage in a
                      conversation. I'm here to help and chat with you.
                    </p>
                    <div className="theme-btn two mt-3">
                      {" "}
                      <a href="https://manage.hostingaviator.cloud/contact.php">Support Center</a>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-envelope-at-fill" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">Email us</span>{" "}
                      <a href="mailto:example@gmail.com">example@gmail.com</a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-phone-flip" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">call</span>{" "}
                      <a href="tel:1234567899">+91 123 456 7890</a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-geo-alt" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">Our location</span>{" "}
                      <a target="_blank" href="https://www.google.com/maps">
                        Open Google Maps
                      </a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
{/*

    <section className="testimonial theme-section-gap bg-secondary-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="theme-section-heading center">
              <h2>
                Hear from happy <span className="highlight">customers</span>
              </h2>
              <p>
                Our satisfied customers love our products/services. Read their
                glowing testimonials to discover why they choose us for quality
                and excellence.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="swiper testimonials">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Aaron Sweeney</h3>
                    <h4>Co-Founder</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Great
                      support, like i have never seen before. Thanks to the
                      support team, they are very helpfull. This company provide
                      customers great solution, that makes them best.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-2.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Animi
                      perferendis, commodi veritatis unde magnam magni? Eaque,
                      reprehenderit dignissimos?{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-3.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Simon Deubele</h3>
                    <h4>Team Member</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> I
                      chose you because you create added value over the price
                      offered by other hosting companies. it's been a great
                      experience for me. I'm leaving happy. Thank you.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>
    </section>
    */}

    <section className="faq theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center mb-4">
            <h2>
              Frequntly <b className="highlight heading">Asked </b> Question
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <div className="faq-qa">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {" "}
                      What is an SSL Certificate ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      An SSL Certificate is a digital certificate issued for a
                      domain by a central authority called the Certificate
                      Authority. To be issued an SSL Certificate, you must
                      purchase an SSL Certificate and then go through a
                      verification process conducted by the Certificate
                      Authority.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {" "}
                      Why should I buy an SSL Certificate ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      An SSL Certificate does 2 things: a. Encrypt the
                      information sent from your user's browser to your website
                      b. Authenticate your website's identity. By doing these 2
                      things, an SSL Certificate protects your customers and in
                      turn increases their trust in your online business. This
                      is especially important if your website requires users to
                      login using passwords or enter sensitive information such
                      as credit card details.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      Do SSL Certificates work in all browsers ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      SSL Certificates are compatible with all major browsers.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {" "}
                      Can I upgrade my SSL Certificates ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Unfortunately, we don't support upgrades/downgrades at the
                      moment. If required you can purchase a new certificate and
                      install it on the same web server as the old certificate{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {" "}
                      Do I need technical expertise to set up an SSL Certificate
                      on my website ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      While it isn't difficult to install an SSL Certificate, it
                      does involve following a series of steps. You can find
                      more information in our KnowledgeBase.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</>

    </div>
  );
};

export default SslCertificates;
