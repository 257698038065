// src/pages/VpsServer.jsx
import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';

import img1 from '../assets/images/1.png';
import img2 from '../assets/images/2.jpg';

const VpsServer = () => {
  return (
    <div>
      <>
  <div className="hero-banner bg2">
    <div className="container">
      <div className="row justify-content-between align-items-center g-4 ">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">KVM VPS</h6>
            <h1 className="mb-3">
              Linux KVM VPS <span className="highlight">Hosting</span>
            </h1>
            <p>
              High-performance Servers for Faster Websites &amp; Applications
            </p>
            <ul className="banner-list mt-3 mb-3">
              <li>High-speed Upgradable SSD Storage</li>
              <li>Instant Provisioning</li>
              <li>Full Root Access</li>
            </ul>
            <a className="theme-btn three width-increase mt-3" href="#plans">
              <i className="bi bi-hdd-stack start" /> Know More
            </a>{" "}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <div className="feature_image bg3">
            {" "}
            <img
        src={img1}
        alt="images6" />{" "}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*
  <main>
    <section className="theme-section-gap tabs-style-one bg2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-12">
            <div className="theme-section-heading">
              <div className="tabings d-flex">
                <div className="tabs tabs-button column-type justify-content-center w-100">
                  <button
                    className="tabbutton active"
                    onclick="openTabings(event, 'Server')"
                  >
                    <i className="bi bi-database-fill" /> Deploy{" "}
                  </button>
                  <button
                    className="tabbutton"
                    onclick="openTabings(event, 'Suports')"
                  >
                    <i className="bi bi-headset" /> Additional Storage{" "}
                  </button>
                  <button
                    className="tabbutton"
                    onclick="openTabings(event, 'Whm')"
                  >
                    <i className="bi bi-boxes" /> Manage{" "}
                  </button>
                  <button
                    className="tabbutton"
                    onclick="openTabings(event, 'Security')"
                  >
                    <i className="bi bi-inboxes-fill" /> Secure{" "}
                  </button>
                  <button
                    className="tabbutton"
                    onclick="openTabings(event, 'Support')"
                  >
                    <i className="bi bi-headset" /> Get Support{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="p-3">
              <div
                id="Server"
                className="tabscontents active"
                style={{ display: "block" }}
              >
                
                <div className="row justify-content-between align-items-center g-5">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="theme-section-heading ">
                      <h3>Instant Provisioning</h3>
                      <p className="mt-2">
                        Get up and running instantly! Our servers are
                        provisioned within a few minutes.
                      </p>
                    </div>
                    <div className="theme-section-heading  mt-3">
                      <h3>Choose your Operating System</h3>
                      <p className="mt-2">
                        Get complete flexibility to choose the operating system
                        that works for you.Here are operating systems available
                        with our servers:
                      </p>
                      <div className="mt-3">
                        {" "}
                        <img src="images/operating.png" alt="" />{" "}
                      </div>
                    </div>
                    <div className="theme-section-heading  mt-3">
                      <h3>Choose your management panel</h3>
                      <p className="mt-2">
                        Optionally, you can choose a Control Panel for easy
                        Server Management.Here the available add-ons:
                      </p>
                      <div className="mt-3">
                        {" "}
                        <img src="images/management.png" alt="" />{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="theme-section-heading">
                      {" "}
                      <img src={img2} alt="vps1" />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="Suports"
                className="tabscontents"
                style={{ display: "none" }}
              >
                <div className="row justify-content-between align-items-center g-5">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="theme-section-heading ">
                      <h3>High-performance Storage Volumes for your VPS</h3>
                      <span className="mt-3 color-one">
                        At $ 21.78/GB/month
                      </span>{" "}
                    </div>
                    <div className="theme-section-heading  mt-3">
                      <h3>Flexible Volumes</h3>
                      <ul>
                        <li>Get a volume starting from 10 GB to 500 GB</li>
                        <li>
                          Add multiple volumes to a server for better space
                          management
                        </li>
                        <li>
                          Upgrade existing volumes upto 500 GB (Coming Soon)
                        </li>
                        <li>
                          Move volumes between servers based on need (Coming
                          Soon)
                        </li>
                      </ul>
                    </div>
                    <div className="theme-section-heading  mt-3">
                      <h3>Simplified Billing</h3>
                      <p className="mt-2">
                        Available at a flat fee. No complicated billing based on
                        Bandwidth or IOPS usage
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="theme-section-heading">
                      {" "}
                      <img src="images/storage.png" alt="vps1" />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="Whm"
                className="tabscontents"
                style={{ display: "none" }}
              >
                <div className="row justify-content-between align-items-center g-5">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="theme-section-heading ">
                      <h3>Server Management</h3>
                      <p>
                        You can Start, Stop, Restart, Rebuild your VPS from the
                        Server Management Panel.
                      </p>
                    </div>
                    <div className="theme-section-heading  mt-3">
                      <h3>Full Root Access</h3>
                      <p>
                        With full root access, you get complete control to
                        manage your server resources.
                      </p>
                    </div>
                    <div className="theme-section-heading  mt-3">
                      <h3>VNC Access</h3>
                      <p className="mt-2">
                        VNC allows you quick access to your VPS for easy
                        management.
                      </p>
                    </div>
                    <div className="theme-section-heading  mt-3">
                      <h3>WHMCS</h3>
                      <p className="mt-2">
                        Optionally, you can install &amp; integrate WHMCS with
                        your VPS Server seamlessly.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="theme-section-heading">
                      {" "}
                      <img src="images/manage.png" alt="vps1" />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="Security"
                className="tabscontents"
                style={{ display: "none" }}
              >
                <div className="row justify-content-between align-items-center g-5">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="theme-section-heading ">
                      <h3>DDOS Protection</h3>
                      <p>
                        Our advanced DDOS protection ensures that your VPS is
                        protected against any attacks.
                      </p>
                    </div>
                    <div className="theme-section-heading  mt-3">
                      <h3>State-of-the-art Infrastructure</h3>
                      <p>
                        All VPS servers are powered by trusted infrastructure to
                        keep them up and running:
                      </p>
                      <div>
                        {" "}
                        <img
                          src="images/infrastructure.png"
                          alt="kvmvpsinfraicons"
                        />{" "}
                      </div>
                    </div>
                    <div className="theme-section-heading  mt-3">
                      <h3>Private Networking</h3>
                      <p className="mt-2">
                        Setup your own network instantly in a few clicks
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="theme-section-heading">
                      {" "}
                      <img src="images/security.png" alt="vps1" />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="Support"
                className="tabscontents"
                style={{ display: "none" }}
              >
                <div className="row justify-content-between align-items-center g-4">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="theme-section-heading ">
                      <h3>
                        We are available 24/7 to help you with your queries
                      </h3>
                      <p>
                        Our servers include semi-managed support related to:
                      </p>
                      <ul>
                        <li>
                          Boot, Login, Investigating Network/Hardware related
                          issues
                        </li>
                        <li>
                          Initial module installation and basic firewall setup
                        </li>
                        <li>Setup and re-installation of KVM VPS</li>
                        <li>Core OS Updates &amp; Patches</li>
                        <li>Reverse DNS Setup</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="theme-section-heading">
                      {" "}
                      <img src="images/support.png" alt="vps1" />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap plan-one bgone" id="plans">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="theme-section-heading text-center">
            <h2>
              Set up your VPS in 2 easy{" "}
              <span className="highlight"> steps</span>
            </h2>
            <p>99% uptime for rock-solid performance</p>
          </div>
        </div>
        <div className="row justify-content-between g-4 mt-4">
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>V1 - SSD</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">9.59</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-3 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>2</span>&nbsp; CPU Cores
                  </li>
                  <li>
                    <span>2</span>&nbsp; GB SSD Disk Space
                  </li>
                  <li>
                    <span>20</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>1</span>&nbsp; Additional IP
                  </li>
                  <li>
                    <span>0</span>&nbsp; TB Bandwidth
                  </li>
                  <li>Free SSL Certificate</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>V2 - SSD</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">17.59</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-3 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>2</span>&nbsp; CPU Cores
                  </li>
                  <li>
                    <span>4</span>&nbsp; GB SSD Disk Space
                  </li>
                  <li>
                    <span>40</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>1</span>&nbsp; Additional IP
                  </li>
                  <li>
                    <span>1</span>&nbsp; TB Bandwidth
                  </li>
                  <li>Free SSL Certificate</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card plan-top-head-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>V3 - SSD</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">37.59</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-3 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>3</span>&nbsp; CPU Cores
                  </li>
                  <li>
                    <span>6</span>&nbsp; GB SSD Disk Space
                  </li>
                  <li>
                    <span>80</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>2</span>&nbsp; Additional IP
                  </li>
                  <li>
                    <span>1</span>&nbsp; TB Bandwidth
                  </li>
                  <li>Free SSL Certificate</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="plan-one-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>V4 - SSD</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">63.59</span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-3 mt-2" />
              <div className="theme-btn two">
                {" "}
                <a href="#">Purchase Plan</a>{" "}
              </div>
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    <span>4</span>&nbsp; CPU Cores
                  </li>
                  <li>
                    <span>8</span>&nbsp; GB SSD Disk Space
                  </li>
                  <li>
                    <span>120</span>&nbsp; GB RAM
                  </li>
                  <li>
                    <span>2</span>&nbsp; Additional IP
                  </li>
                  <li>
                    <span>1</span>&nbsp; TB Bandwidth
                  </li>
                  <li>Free SSL Certificate</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap customer-support">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-12">
            <div className="bg2 customer-support-box">
              <div className="row justify-content-between g-5">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="theme-section-heading text-start">
                    <h6 className="style">Contact Us</h6>
                    <h2 className="mt-3">
                      Any query ? <span className="highlight">let's talk</span>
                    </h2>
                    <p>
                      Any questions or topics you'd like to discuss? Feel free
                      to share your thoughts, and let's engage in a
                      conversation. I'm here to help and chat with you.
                    </p>
                    <div className="theme-btn two mt-3">
                      {" "}
                      <a href="https://manage.hostingaviator.cloud/contact.php">Support Center</a>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-envelope-at-fill" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">Email us</span>{" "}
                      <a href="mailto:example@gmail.com">example@gmail.com</a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-phone-flip" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">call</span>{" "}
                      <a href="tel:1234567899">+91 123 456 7890</a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                  <div className="customer-support-text text-center">
                    <div>
                      {" "}
                      <span className="icon">
                        {" "}
                        <i className="bi bi-geo-alt" />{" "}
                      </span>{" "}
                    </div>
                    <div className="text-start cus-email">
                      {" "}
                      <span className="lable">Our location</span>{" "}
                      <a target="_blank" href="https://www.google.com/maps">
                        Open Google Maps
                      </a>{" "}
                    </div>
                    <div className="icon-2">
                      {" "}
                      <i className="bi bi-arrow-right-circle-fill" />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="testimonial theme-section-gap bg-secondary-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="theme-section-heading center">
              <h2>
                Hear from happy <span className="highlight">customers</span>
              </h2>
              <p>
                Our satisfied customers love our products/services. Read their
                glowing testimonials to discover why they choose us for quality
                and excellence.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="swiper testimonials">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Aaron Sweeney</h3>
                    <h4>Co-Founder</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Great
                      support, like i have never seen before. Thanks to the
                      support team, they are very helpfull. This company provide
                      customers great solution, that makes them best.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-2.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Animi
                      perferendis, commodi veritatis unde magnam magni? Eaque,
                      reprehenderit dignissimos?{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas-3.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Simon Deubele</h3>
                    <h4>Team Member</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> I
                      chose you because you create added value over the price
                      offered by other hosting companies. it's been a great
                      experience for me. I'm leaving happy. Thank you.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <section className="faq theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center mb-4">
            <h2>
              Frequntly <b className="highlight heading">Asked </b> Question
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <div className="faq-qa">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {" "}
                      What are the advantages of VPS Hosting ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      With VPS Hosting, you have complete isolation. This means
                      that no matter what other users might be doing on the
                      server, your VPS package will be unaffected. With root
                      access, you can install whatever applications you require.
                      VPS also gives you guaranteed resources, so no matter
                      what, the CPU, RAM, Disk Space and Bandwidth allocated to
                      your VPS package will always be available for your
                      applications. It gives you all the features and functions
                      of Dedicated server, without the additional cost.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {" "}
                      What is a KVM VPS ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      A KVM (Kernel-based Virtual Machine) is a virtual private
                      server which sits on top of a physical dedicated server.
                      Resources are dedicated to the KVM VPS and are not shared
                      with other users on the physical device, offering
                      tremendous reliability and performance.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      What is the difference between KVM and OpenVZ ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      A KVM is true virtualization where the VPS operates as its
                      own server, independently of the host node. OpenVZ is a
                      container based virtualization which relies on the host
                      node's kernel{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      {" "}
                      Is a Dedicated IP available ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, at an additional cost. You can raise a support ticket
                      to get a dedicated IP.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      {" "}
                      Do you include protection from viruses ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, all our servers are protected by Clam AV.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      {" "}
                      What is your backup policy ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      You are responsible for your backups and web content. It
                      is recommended that you keep copies of your content safe
                      and make your own backups. You can take a backup from your
                      cPanel or use a remote backup solution.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  */}

<div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#f4f4f4",
        color: "#333",
        fontFamily: "Arial, sans-serif",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          fontSize: "3rem",
          color: "#0066cc",
          marginBottom: "10px",
        }}
      >
        Coming Soon
      </h1>
      <p
        style={{
          fontSize: "1.5rem",
          marginBottom: "30px",
        }}
      >
        Our VPS Hosting is launching soon. Stay tuned for updates!
      </p>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            fontSize: "2rem",
            padding: "10px 20px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          00
        </div>
        <div
          style={{
            fontSize: "2rem",
            padding: "10px 20px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          00
        </div>
        <div
          style={{
            fontSize: "2rem",
            padding: "10px 20px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          00
        </div>
        <div
          style={{
            fontSize: "2rem",
            padding: "10px 20px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          00
        </div>
      </div>
      <button
        style={{
          padding: "10px 20px",
          fontSize: "1rem",
          color: "#fff",
          backgroundColor: "#0066cc",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={() => alert("You will be notified!")}
      >
        Notify Me
      </button>
    </div>
</>

    </div>
  );
};

export default VpsServer;
