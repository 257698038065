// src/pages/WebsiteBuilder.jsx
import React from 'react';
import website from '../assets/images/website.png'


const WebsiteBuilder = () => {
  return (
    <div>
     <>
  <div className="hero-banner bg1">
    <div className="container">
      <div className="row justify-content-between align-items-center g-5 ">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">Website Builder</h6>
            <h1 className="mb-3">
              Create A Beautiful <span className="highlight">Website</span> In
              Minutes
            </h1>
            <p>
              Manage less and focus more on what's important - your business!{" "}
            </p>
            <ul className="banner-list mt-3 mb-3">
              <li>Choose from 175 themes &amp; 85,000 images</li>
              <li>Easy Editing with a Drag &amp; Drop Interface</li>
              <li>Go Live Instantly - Publish your website in minutes </li>
            </ul>
            <a className="theme-btn three width-increase mt-3" href="#plans">
              View More <i className="ri-arrow-right-line" />
            </a>{" "}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          {" "}
          <img src={website} alt="website" />{" "}
        </div>
      </div>
    </div>
  </div>
  <main>
    <section className="theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center">
            <h2>
              Why Do You Need{" "}
              <span className="highlight">Website Builder </span>
            </h2>
            <p>Checkout These Features</p>
          </div>
        </div>
        <div className="row justify-content-center align-item-stretch mt-4 g-4">
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="service-style-two-card">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-bookmark-check"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                  />
                  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>
                  {" "}
                  Easy to Use <span className="highlight"> Visual </span>Editor
                </h3>
                <ul className="list-tick mt-3 mb-3">
                  <li>Real Time Editor - See the changes as you make them! </li>
                  <li>Intuitive Drag &amp; Drop Interface</li>
                  <li>No Coding skills required!</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="service-style-two-card">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-card-list"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                  <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>
                  {" "}
                  30+ Dynamic <span className="highlight"> Components </span>
                </h3>
                <ul className="list-tick mt-3 mb-3">
                  <li>
                    Dynamic components including Image Galleries, Blogs and Web
                    Forms{" "}
                  </li>
                  <li>Ecommerce components like PayPal &amp; Ebay included</li>
                  <li>Social widgets for instant Social Integration</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="service-style-two-card">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-backpack4-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a2 2 0 0 0-2 2H3.5a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h4v.5a.5.5 0 0 0 1 0V7h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H10a2 2 0 0 0-2-2Zm1 2a1 1 0 0 0-2 0h2Zm-4 9v2h6v-2h-1v.5a.5.5 0 0 1-1 0V11H5Z" />
                  <path d="M14 7.599A2.986 2.986 0 0 1 12.5 8H9.415a1.5 1.5 0 0 1-2.83 0H3.5A2.986 2.986 0 0 1 2 7.599V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7.599ZM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-3Z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>
                  {" "}
                  180 Themes and <span className="highlight"> Counting </span>
                </h3>
                <ul className="list-tick mt-3 mb-3">
                  <li>
                    A Design for every need - Personal, Blog and Professional
                    Themes available{" "}
                  </li>
                  <li>Access to 85,000 stock images</li>
                  <li>Completely Customizable</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="service-style-two-card">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-backpack3"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.04 7.43a4 4 0 0 1 7.92 0 .5.5 0 1 1-.99.14 3 3 0 0 0-5.94 0 .5.5 0 1 1-.99-.14ZM4 9.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-4Zm1 .5v3h6v-3h-1v.5a.5.5 0 0 1-1 0V10H5Z" />
                  <path d="M6 2.341V2a2 2 0 1 1 4 0v.341c.465.165.904.385 1.308.653l.416-1.247a1 1 0 0 1 1.748-.284l.77 1.027a1 1 0 0 1 .15.917l-.803 2.407C13.854 6.49 14 7.229 14 8v5.5a2.5 2.5 0 0 1-2.5 2.5h-7A2.5 2.5 0 0 1 2 13.5V8c0-.771.146-1.509.41-2.186l-.802-2.407a1 1 0 0 1 .15-.917l.77-1.027a1 1 0 0 1 1.748.284l.416 1.247A5.978 5.978 0 0 1 6 2.34ZM7 2v.083a6.04 6.04 0 0 1 2 0V2a1 1 0 1 0-2 0Zm5.941 2.595.502-1.505-.77-1.027-.532 1.595c.297.284.566.598.8.937ZM3.86 3.658l-.532-1.595-.77 1.027.502 1.505c.234-.339.502-.653.8-.937ZM8 3a5 5 0 0 0-5 5v5.5A1.5 1.5 0 0 0 4.5 15h7a1.5 1.5 0 0 0 1.5-1.5V8a5 5 0 0 0-5-5Z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>
                  {" "}
                  <span className="highlight"> Completely </span> Hosted
                  Solution
                </h3>
                <ul className="list-tick mt-3 mb-3">
                  <li>Powerful Web and Email Hosting included </li>
                  <li>
                    Completely safe and secure with a 99.9% Uptime Guarantee
                  </li>
                  <li>Host your Website on your own Domain Name</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="service-style-two-card">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-bookmarks"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z" />
                  <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>
                  {" "}
                  Unique <span className="highlight"> Features </span>
                </h3>
                <ul className="list-tick mt-3 mb-3">
                  <li>Built-In website statistics </li>
                  <li>Blog portability</li>
                  <li>Search engine optimization</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap plan-two  bgtwo" id="plans">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="theme-section-heading text-center">
            <h2>
              Get your business{" "}
              <span className="highlight"> online today </span>
            </h2>
            <p>99% uptime for rock-solid performance </p>
          </div>
        </div>
        <div className="row justify-content-center g-4 mt-4">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="plan-two-card text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Combo Website Plan</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">54.34 </span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    Pages<span>&nbsp; 5</span>
                  </li>
                  <li>
                    Web Space -<span>&nbsp; 2 GB </span>{" "}
                  </li>
                  <li>
                    Data Transfer<span>&nbsp;- unmetered </span>{" "}
                  </li>
                  <li>
                    Email Accounts<span>&nbsp; - 10 </span>{" "}
                  </li>
                </ul>
                <div className="select">
                  <select>
                    <option>1 Month at $ 54.34/mo</option>
                  </select>
                </div>
              </div>
              <div className="border mb-2 mt-2" />
              <div className="theme-btn two mt-3">
                {" "}
                <a href="#">Buy Plan</a>{" "}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="plan-two-card active text-center theme-section-heading">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Ssl Hosting</h3>
              </div>
              <span className="price text-start">
                <sup>$ &nbsp;</sup>
                <span className="highlight">43.12 </span>
                <sub>&nbsp;/mo</sub>
              </span>
              <div className="border mb-2 mt-2" />
              <div className="plan-listing mt-2 mb-2">
                <ul>
                  <li>
                    Pages<span>&nbsp; 50 </span>
                  </li>
                  <li>
                    Web Space -<span>&nbsp; 5 GB </span>{" "}
                  </li>
                  <li>
                    Data Transfer<span>&nbsp;- unmetered </span>{" "}
                  </li>
                  <li>
                    Email Accounts<span>&nbsp; - 10 </span>{" "}
                  </li>
                </ul>
                <div className="select white-bg">
                  <select>
                    <option>1 Month at $ 43.12/mo</option>
                  </select>
                </div>
              </div>
              <div className="border mb-2 mt-2" />
              <div className="theme-btn two mt-3">
                {" "}
                <a href="#">Buy Plan</a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="theme-section-gap tabs-style-one">
      <div className="container">
        <div className="row justify-content-center align-items-center g-4">
          <div className="col-lg-8 col-12">
            <div className="p-3">
              <div
                id="Server"
                className="tabscontents active"
                style={{ display: "block" }}
              >
                <div className="row align-items-center gap-y-25">
                  <div className="theme-section-heading">
                    <h4 className="fs-25 fw-600  mb-3">
                      {" "}
                      <span className="highlight">Online Website </span> Editor
                      &amp; Content Management Features{" "}
                    </h4>
                  </div>
                  <div className="col-lg-6 col-12">
                    <ul>
                      <li>Visual Editor</li>
                      <li>1, 2 and 3 column layouts</li>
                      <li>Style importing and exporting</li>
                      <li>Standard HTML and CSS Templates</li>
                      <li>Drag and Drop components</li>
                      <li>Built in lightbox feature</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-12">
                    <ul>
                      <li>Over 175 themes and counting</li>
                      <li>100% customizable page layouts</li>
                      <li>Favicon customization</li>
                      <li>Real time Editor</li>
                      <li>Easy Image Uploading and Editing</li>
                      <li>Access to stock photo library (85000 images)</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                id="Suports"
                className="tabscontents"
                style={{ display: "none" }}
              >
                <div className="row align-items-center gap-y-25">
                  <div className="theme-section-heading">
                    <h4 className="fs-25 fw-600  mb-3">
                      {" "}
                      <span className="highlight">Website Marketing </span>{" "}
                      Features{" "}
                    </h4>
                  </div>
                  <div className="col-lg-6 col-12">
                    <ul>
                      <li>Social Network Integration</li>
                      <li>Monitor Your Website Usage</li>
                      <li>Customized Forms Builder</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-12">
                    <ul>
                      <li>Blog Portability</li>
                      <li>Top Referrers Analysis Page</li>
                      <li>Optimize your website for search engines</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                id="Whm"
                className="tabscontents"
                style={{ display: "none" }}
              >
                <div className="row align-items-center gap-y-25">
                  <div className="theme-section-heading">
                    <h4 className="fs-25 fw-600  mb-3">
                      {" "}
                      Website <span className="highlight">
                        {" "}
                        Components{" "}
                      </span>{" "}
                    </h4>
                  </div>
                  <div className="col-lg-6 col-12">
                    <ul>
                      <li>Image Gallery</li>
                      <li>Form</li>
                      <li>PayPal</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-12">
                    <ul>
                      <li>Blog</li>
                      <li>Google Maps</li>
                      <li>eBay</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="theme-section-heading">
              <div className="tabings">
                <div className="tabs column-type">
                  <button
                    className="tabbutton active"
                    onclick="openTabings(event, 'Server')"
                  >
                    <i className="bi bi-database-fill" /> Editers{" "}
                  </button>
                  <button
                    className="tabbutton"
                    onclick="openTabings(event, 'Suports')"
                  >
                    <i className="bi bi-headset" /> Features{" "}
                  </button>
                  <button
                    className="tabbutton"
                    onclick="openTabings(event, 'Whm')"
                  >
                    <i className="bi bi-boxes" /> Components{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
{/*

    <section className="testimonial theme-section-gap  bgone">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="theme-section-heading center">
              <h2>
                Hear from happy <span className="highlight">customers</span>
              </h2>
              <p>
                Our satisfied customers love our products/services. Read their
                glowing testimonials to discover why they choose us for quality
                and excellence.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="swiper testimonials">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Aaron Sweeney</h3>
                    <h4>Co-Founder</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Great
                      support, like i have never seen before. Thanks to the
                      support team, they are very helpfull. This company provide
                      customers great solution, that makes them best.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Animi
                      perferendis, commodi veritatis unde magnam magni? Eaque,
                      reprehenderit dignissimos?{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    {" "}
                    <img
                      src="images/testimonilas.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Simon Deubele</h3>
                    <h4>Team Member</h4>
                    <p>
                      {" "}
                      <i className="ri-double-quotes-l quote-icon-left" /> I
                      chose you because you create added value over the price
                      offered by other hosting companies. it's been a great
                      experience for me. I'm leaving happy. Thank you.{" "}
                      <i className="ri-double-quotes-r quote-icon-right" />{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>
    </section>
    */}
    <section className="faq theme-section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="theme-section-heading text-center mb-4">
            <h2>
              Frequntly <b className="highlight heading">Asked </b> Question
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <div className="faq-qa">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {" "}
                      Do I have to purchase a Hosting package to buy a Website
                      Builder package ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      No, you don't have to worry about that. Our Website
                      Builder packages come with a free Web and Email Hosting
                      package.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {" "}
                      Is there a demo version of your Website Builder ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, we do have a <a href="#">demo</a> that you can try
                      out before buying a Website Builder package.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      Will my Website be Responsive ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, we offer a Risk Free, 30 Day Money Back Guarantee
                      with all our Website Builder packages.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {" "}
                      What components are included with your Website Builder ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Our Website builder includes popular components like Blog,
                      Image Gallery, PayPal, eBay and Google Maps.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {" "}
                      Can I design/upload my own Theme ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      You can choose from over 180 stunning themes designed by
                      expert designers. Our themes can be used for personal,
                      professional as well as ecommerce Websites. You can also{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      {" "}
                      Can I upgrade to a higher plan ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Yes, you can upgrade to a higher plan at any time.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      {" "}
                      Will there be any advertisements on my site ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Absolutely Not! All our Website Builder packages are
                      completely Ad-Free.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      {" "}
                      Does the Website Builder include any SEO feature ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      Our Website Builder packages come integrated with a Search
                      Engine Optimization feature to help you rank higher on
                      search engines.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      {" "}
                      I own a website with another host - can I move my site on
                      to website builder ?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      No, I'm afraid you will not be able to migrate an existing
                      Website directly on to a Website Builder package. However,
                      you can move website files over by downloading them from
                      your other host then uploading them to your website
                      builder account. The website content can then be filled to
                      achieve a similar result.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</>

    </div>
  );
};

export default WebsiteBuilder;
