import React from 'react';
import { Helmet } from 'react-helmet';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/images/logo.png';
import './navbar.css';
import { FaUser, FaGlobe, FaDatabase, FaEnvelope, FaTools, FaGift, FaCoffee } from 'react-icons/fa';  // Font Awesome icons
import { MdAlarm } from 'react-icons/md';  // Material Design icons

const CustomNavbar = () => {
  return (
    <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>Hosting Aviator - Best Web Hosting & Domain Provider</title>
  <meta name="description" content="Hosting Aviator offers affordable, high-performance web hosting, domain services, and more. Reliable hosting solutions for your online business." />
  <meta name="keywords" content="HOSTING AVIATOR, HOSTING AVIATOR web hosting, HOSTING AVIATOR review, HOSTING AVIATOR coupon, HOSTING AVIATOR hosting, HOSTING AVIATOR domain, HOSTING AVIATOR.com, webhosting, web hosting service, affordable web hosting, cpanel hosting, website hosting, best hosting, domain hosting, reseller hosting, best web hosting, basic web hosting, best domain hosting service, cloud web hosting providers, best cloud hosting providers, website hosting companies, cloud web server hosting, web hosting provider, top cloud hosting providers, best shared web hosting, best cloud web hosting providers, best cloud web hosting services, website hosting and domain, business web hosting, hosting package, domain and hosting, internet hosting service, domain name hosting, shared hosting" />
  <meta name="author" content="Hosting Aviator" />
  <meta property="og:title" content="Hosting Aviator - Best Web Hosting & Domain Provider" />
  <meta property="og:description" content="Hosting Aviator offers affordable, high-performance web hosting, domain services, and more. Reliable hosting solutions for your online business." />
  <meta property="og:image" content="https://www.hostingaviator.cloud/logo.png" />
  <meta property="og:url" content="https://www.hostingaviator.cloud" />
  <meta property="og:type" content="website" />
</Helmet>


      <header className="theme-main-menu" id="header">
        <div className="container d-flex align-items-center justify-content-between bg-gradient rounded shadow-lg">
          <div className="logo order-xl-0">
            <Link to="/">
              <img src={logo} alt="logo" style={{ height: '80px', width: '250px' }} />
            </Link>
          </div>

          <NavDropdown title={<><FaUser /> Login</>} id="login-dropdown" className="theme-btn d-none d-xl-block order-xl-3">
            <NavDropdown.Item as={Link} to="https://manage.hostingaviator.cloud/register.php"><FaCoffee /> Create An Account</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="https://manage.hostingaviator.cloud/index.php?rp=/login"><MdAlarm /> Sign In</NavDropdown.Item>
          </NavDropdown>

          <Navbar expand="xl" className="order-xl-2">
            <Navbar.Toggle aria-controls="navbarNav" />
            <Navbar.Collapse id="navbarNav">
              <Nav className="me-auto">
                <Nav.Item className="d-block d-xl-none">
                  <div className="logo">
                    <Link to="/">
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                </Nav.Item>

                {/* Domain Dropdown */}
                <NavDropdown title={<><FaGlobe /> Domain</>} id="domainDropdown">
                  <NavDropdown.Item as={Link} to="/domain-register"><FaCoffee /> Register a Domain</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/domain-transfer"><MdAlarm /> Transfer Your Domain</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/domain-promos"><FaGift /> Domain Promos</NavDropdown.Item>
                </NavDropdown>

                {/* Hosting Dropdown */}
                <NavDropdown title={<><FaDatabase /> Hosting</>} id="hostingDropdown">
                  <NavDropdown.Item as={Link} to="/linux-hosting"><FaDatabase /> Linux Shared Hosting</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/wordpress-hosting"><FaDatabase /> WordPress Hosting</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/vps-server"><FaDatabase /> Linux KVM VPS</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/dedicated-server"><FaDatabase /> Dedicated Server</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/reseller-hosting"><FaDatabase /> Linux Reseller Hosting</NavDropdown.Item>
                </NavDropdown>

                {/* Email & Security Dropdown */}
                <NavDropdown title={<><FaEnvelope /> Email & Security</>} id="emailSecurityDropdown">
                  <NavDropdown.Item as={Link} to="/business-email"><FaEnvelope /> Business Email</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/enterprise-email"><FaEnvelope /> Enterprise Email</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/google-workspace"><FaEnvelope /> Google Workspace</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/ssl-certificates"><FaEnvelope /> SSL Certificates</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/sitelock"><FaEnvelope /> Sitelock</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/codeguard-backup"><FaEnvelope /> Codeguard Backup</NavDropdown.Item>
                </NavDropdown>

                {/* Tools Dropdown */}
                <NavDropdown title={<><FaTools /> Tools</>} id="toolsDropdown">
                  <NavDropdown.Item as={Link} to="/acronis-backup"><FaTools /> Acronis Cyber Backup</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/website-builder"><FaTools /> Website Builder</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/professional-cloud"><FaTools /> Professional Cloud</NavDropdown.Item>
                </NavDropdown>

                

                {/* Combo Offer */}
                <Nav.Link as={Link} to="/combo-offer"><FaGift /> Combo Offer</Nav.Link>
              </Nav>

              {/* Mobile Login Button */}
              <div className="mobile-content d-block d-xl-none">
                <div className="d-flex flex-column align-items-center justify-content-center mt-3">
                  <Link to="https://manage.hostingaviator.cloud/index.php?rp=/login" className="theme-btn"><FaUser /> Login</Link>
                </div>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
    </>
  );
};

export default CustomNavbar;
