import React from 'react';
import './About.css';
import website from '../assets/images/website.png'

const AboutUs = () => {
  return (
    <div>
      <div className="hero-banner bg1">
    <div className="container">
      <div className="row justify-content-between align-items-center g-5 ">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="inner-content">
            <h6 className="style">About  Us</h6>
            <h1 className="mb-3">
              Here Is About <span className="highlight">Hosting Aviator</span> 
            </h1>
            <p>
              Manage less and focus more on what's important - your business!{" "}
            </p>
            
            {" "}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          {" "}
          <img src={website} alt="website" />{" "}
        </div>
      </div>
    </div>
  </div>
      <section className="theme-section-gap">
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="theme-section-heading text-center">
            <h2>
              <span className="highlight">About Us</span> Hosting Aviator
            </h2>
          </div>
        </div>
        <div className="row justify-content-center align-items-stretch g-5">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-hdd-network" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-hdd-network"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a.5.5 0 0 1 0 1H10A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5H.5a.5.5 0 0 1 0-1H6A1.5 1.5 0 0 1 7.5 10V7H2a2 2 0 0 1-2-2V4zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1zm6 7.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5z" />
                </svg>
              </div>
              <div className="card-content">
                <h3> Introduction</h3>
                <p>
                Hosting Aviator is a dynamic and innovative web hosting and domain provider dedicated to serving the Diverse needs of businesses and individuals in the digital landscape. Established in 2022 by its founder, Avinash Yadav, Hosting Aviator swiftly ascended to become a leading force in the web hosting industry, particularly within the Indian market. Our mission is clear: to empower and enable the online presence of our clients, regardless of their size or ambition. We pride ourselves on delivering affordable and dependable web hosting solutions, boasting an impressive 99.9% uptime record and providing round-the-clock, unwavering customer support. Our comprehensive range of services includes shared hosting, cloud hosting, premium hosting, reseller hosting, and domain registration.
                </p>
                {" "}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-journals" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-journals"
                  viewBox="0 0 16 16"
                >
                  <path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2z" />
                  <path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>Our Commitment to Excellence</h3>
                <p>
                At Hosting Aviator, our vision is firmly rooted in the belief that the internet is not just a platform; it's a catalyst for transformative change and boundless opportunities. We envision a digital landscape where businesses of all sizes, from startups to enterprises, can thrive and expand their horizons with ease. Our commitment is to empower individuals and organizations alike to navigate this dynamic online world effortlessly. We see a future where cutting-edge technology meets affordability, where 24x7 support is the norm, and where innovation knows no bounds. Hosting Aviator strives to be at the forefront of this digital evolution, delivering exceptional web hosting and domain services that fuel the growth and success of our clients. We're not just hosting websites; we're nurturing dreams, driving progress, and shaping the future of the online business landscape. Join us in realizing this vision, and together, we'll unlock the true potential of the internet.
                </p>
                {" "}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="service-style-three-card theme-section-heading">
              {" "}
              <i className="bi bi-windows" />
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-windows"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.555 1.375 0 2.237v5.45h6.555V1.375zM0 13.795l6.555.933V8.313H0v5.482zm7.278-5.4.026 6.378L16 16V8.395H7.278zM16 0 7.33 1.244v6.414H16V0z" />
                </svg>
              </div>
              <div className="card-content">
                <h3>Our Vision</h3>
                <p>
                At Hosting Aviator, our vision is firmly rooted in the belief that the internet is not just a platform; it's a catalyst for transformative change and boundless opportunities. We envision a digital landscape where businesses of all sizes, from startups to enterprises, can thrive and expand their horizons with ease. Our commitment is to empower individuals and organizations alike to navigate this dynamic online world effortlessly. We see a future where cutting-edge technology meets affordability, where 24x7 support is the norm, and where innovation knows no bounds. Hosting Aviator strives to be at the forefront of this digital evolution, delivering exceptional web hosting and domain services that fuel the growth and success of our clients. We're not just hosting websites; we're nurturing dreams, driving progress, and shaping the future of the online business landscape. Join us in realizing this vision, and together, we'll unlock the true potential of the internet.
                </p>
                {" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default AboutUs;
