// src/components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'; // Assuming you have a CSS file for the 404 page styling
import bgImage from '../assets/images/404.jpg';  // Import the image

const NotFound = () => {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1
            style={{
              background: `url(${bgImage}) no-repeat`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '230px',
              fontWeight: '900',
              margin: '0',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            Oops!
          </h1>
        </div>
        <h2>404 - Page not found</h2>
        <p>
          The page you are looking for might have been removed, had its name changed,
          or is temporarily unavailable.
        </p>
        <Link to="/">Go To Homepage</Link>
      </div>
    </div>
  );
};

export default NotFound;
