// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar'; // Updated to CustomNavbar for consistency
import Footer from './components/Footer';
import Home from './pages/Home';

import Contact from './pages/Contact';

import NotFound from './components/NotFound'; // 404 page

import MyTeam from './pages/MyTeam'; // Add this import
import AboutUs from './pages/AboutUs'; // Add this import
import ContactSupport from './pages/ContactSupport'; 

import DomainTransfer from './pages/DomainTransfer';
import DomainPromos from './pages/DomainPromos';
import DomainRegistration from './pages/DomainRegistration'; // Add this import

import LinuxHosting from './pages/LinuxHosting';
import WordPressHosting from './pages/WordPressHosting';
import VpsServer from './pages/VpsServer';
import DedicatedServer from './pages/DedicatedServer';
import ResellerHosting from './pages/ResellerHosting';

import BusinessEmail from './pages/BusinessEmail';
import EnterpriseEmail from './pages/EnterpriseEmail';
import GoogleWorkspace from './pages/GoogleWorkspace';
import SslCertificates from './pages/SslCertificates';
import Sitelock from './pages/Sitelock';
import CodeguardBackup from './pages/CodeguardBackup';

import AcronisBackup from './pages/AcronisBackup';
import WebsiteBuilder from './pages/WebsiteBuilder';
import ProfessionalCloud from './pages/ProfessionalCloud';
import ComboOffer from './pages/ComboOffer';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
       
        <Route path="/contact" element={<Contact />} />

        {/* Domain Routes */}
        <Route path="/domain-register" element={<DomainRegistration />} />
        <Route path="/domain-transfer" element={<DomainTransfer />} />
        <Route path="/domain-promos" element={<DomainPromos />} />

        {/* Hosting Routes */}
        <Route path="/linux-hosting" element={<LinuxHosting />} />
        <Route path="/wordpress-hosting" element={<WordPressHosting />} />
        <Route path="/vps-server" element={<VpsServer />} />
        <Route path="/dedicated-server" element={<DedicatedServer />} />
        <Route path="/reseller-hosting" element={<ResellerHosting />} />

        {/* Email & Security Routes */}
        <Route path="/business-email" element={<BusinessEmail />} />
        <Route path="/enterprise-email" element={<EnterpriseEmail />} />
        <Route path="/google-workspace" element={<GoogleWorkspace />} />
        <Route path="/ssl-certificates" element={<SslCertificates />} />
        <Route path="/sitelock" element={<Sitelock />} />
        <Route path="/codeguard-backup" element={<CodeguardBackup />} />

        {/* Tools Routes */}
        <Route path="/acronis-backup" element={<AcronisBackup />} />
        <Route path="/website-builder" element={<WebsiteBuilder />} />
        <Route path="/professional-cloud" element={<ProfessionalCloud />} />

        {/* Other Pages */}
        <Route path="/my-team" element={<MyTeam />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-support" element={<ContactSupport />} />

        {/* Combo Offer */}
        <Route path="/combo-offer" element={<ComboOffer />} />
        <Route path="/terms-condition" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/refund-policy" element={<RefundPolicy />} />

        {/* Catch-all 404 Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
